import {InvestorGroupMemberType, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import moment from "moment";
import {ISO8601_DATE_FORMAT} from "../../constants/common";
import {InflowTimeFrame} from "../models/GeneralInflow";

export function extractYear(date: string) {
    return moment(date, ISO8601_DATE_FORMAT).year();
}

export function isLeapYear(year: number){
    let leapDate = new Date(year, 1,29);
    return leapDate.getMonth() === 1 && leapDate.getDate() === 29;
}

export function calculateDateWithYear(date: string, year: number) {
    const dateMoment = moment(date, ISO8601_DATE_FORMAT);
    const momentWithYear = moment({
        year: year,
        month: dateMoment.month(),
        date: (!isLeapYear(year) && dateMoment.month() == 1 && dateMoment.date() == 29) ? dateMoment.date()-1: dateMoment.date(),
    });
    return momentWithYear.format(ISO8601_DATE_FORMAT);
}

export function calculateStartDate(birthdate: string | undefined, yearsUntilFlow: number) {
    const today = moment();
    const birthDayThisYear = moment(birthdate, ISO8601_DATE_FORMAT).year(today.year());

    let startDate = birthDayThisYear.isSameOrBefore(today, 'day') ?
        birthDayThisYear : birthDayThisYear.year(birthDayThisYear.year() - 1);

    return startDate.add(yearsUntilFlow, 'year').format(ISO8601_DATE_FORMAT);
}

export function calculateEndDate(birthdate: string | undefined, yearsOfFlow: number, startDate: string) {
    const today = moment();
    const thisYear = today.year();
    const birthDayThisYear = moment(birthdate, ISO8601_DATE_FORMAT).year(thisYear);
    const mostRecentBirthDay = today.isSameOrAfter(birthDayThisYear, 'day') ? birthDayThisYear : birthDayThisYear.year(thisYear - 1);
    const startDateMoment = moment(startDate, ISO8601_DATE_FORMAT);
    const inflowHasStarted = today.isSameOrAfter(startDateMoment, 'day');
    const baseDateMoment = inflowHasStarted ? mostRecentBirthDay : startDateMoment;
    const endDate = baseDateMoment.add(yearsOfFlow, 'year');
    return endDate.format(ISO8601_DATE_FORMAT);
}

const getBirthdateForSelectedOwner = (investorGroup: InvestorGroupType, ownerMemberId: string) => {
    if (investorGroup?.primaryMember.id === ownerMemberId) {
        return investorGroup?.primaryMember.birthdate;
    } else {
        return investorGroup?.partnerMember?.birthdate;
    }
}

export const getBirthdateForOwnerWithLongerPlanningPeriod = (investorGroup: InvestorGroupType) => {
    if (investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PRIMARY) {
        return investorGroup?.primaryMember.birthdate;
    } else {
        return investorGroup?.partnerMember?.birthdate;
    }
}

export const getMemberWithLongestPlanningPeriod = (investorGroup: InvestorGroupType) => {
    let memberWithLongestPlanningPeriod = investorGroup.primaryMember;

    if ((investorGroup.partnerMember?.planningPeriod?.numberOfYears ?? 0) > (investorGroup.primaryMember.planningPeriod?.numberOfYears ?? 0)) {
        memberWithLongestPlanningPeriod = investorGroup.partnerMember!;
    }

    return memberWithLongestPlanningPeriod;
};

export function getOwnersAgeRange(investorGroup: InvestorGroupType, ownerMemberId: string, startDate: string | null, endDate: string | null) {
    let ownersAgeRangeFrom = 0;
    let ownersAgeRangeTo = 0;

    if (startDate && endDate) {
        const ownersBirthdate = getBirthdateForSelectedOwner(investorGroup, ownerMemberId);
        ownersAgeRangeFrom = moment(startDate).diff(ownersBirthdate, 'years');
        ownersAgeRangeTo = moment(endDate).diff(ownersBirthdate, 'years');
    }

    return {
        ownersAgeRangeFrom,
        ownersAgeRangeTo
    }
}

export default class InflowTimeFrameCalculator {
    private investorGroup: InvestorGroupType;

    constructor(investorGroup: InvestorGroupType) {
        this.investorGroup = investorGroup;
    }

    getInflowYearsFrom(today: moment.Moment, startDate: moment.Moment) {
        if (today.isSameOrAfter(startDate)) {
            if (today.month() > startDate.month()) {
                return today.year()
            } else if (today.month() == startDate.month() && today.date() >= startDate.date()) {
                return today.year()
            } else {
                return today.year() - 1
            }
        }
        return startDate.year();
    }


    calculateUsingYearsUntilFlow(yearsUntilFlow: number,
                                 otherTimeFrameFields: Omit<InflowTimeFrame, 'yearsUntilFlow'>,
                                 previousYearsUntilFlow: number): InflowTimeFrame {
        if (yearsUntilFlow + otherTimeFrameFields.yearsOfFlow > this.investorGroup.planningPeriod.numberOfYears) {
            return {
                ...otherTimeFrameFields,
                yearsUntilFlow: previousYearsUntilFlow,
            };
        }

        const delta = yearsUntilFlow - previousYearsUntilFlow;
        const inflowYearsFrom = otherTimeFrameFields.inflowYearsFrom + delta;
        const inflowYearsTo = otherTimeFrameFields.inflowYearsTo + delta;
        const startDate = calculateDateWithYear(otherTimeFrameFields.startDate!, inflowYearsFrom);
        const endDate = calculateDateWithYear(otherTimeFrameFields.endDate!, inflowYearsTo);
        const ownersAgeRangeFrom = otherTimeFrameFields.ownersAgeRangeFrom + delta;
        const ownersAgeRangeTo = otherTimeFrameFields.ownersAgeRangeTo + delta;

        return {
            ...otherTimeFrameFields,
            startDate,
            endDate,
            ownersAgeRangeFrom,
            ownersAgeRangeTo,
            yearsUntilFlow,
            inflowYearsFrom,
            inflowYearsTo,
        };
    }

    calculateUsingYearsOfFlow(yearsOfFlow: number, otherTimeFrameFields: Omit<InflowTimeFrame, 'yearsOfFlow'>, currentDate: Date) {
        yearsOfFlow = Math.min(this.investorGroup.planningPeriod.numberOfYears - otherTimeFrameFields.yearsUntilFlow, yearsOfFlow);
        const today = moment(currentDate);
        const startDate = moment(otherTimeFrameFields.startDate);
        const inflowYearsFrom = this.getInflowYearsFrom(today, startDate);
        const inflowYearsTo = inflowYearsFrom + yearsOfFlow;
        const endDate = calculateDateWithYear(otherTimeFrameFields.endDate!, inflowYearsTo)
        const delta = inflowYearsTo - otherTimeFrameFields.inflowYearsTo
        const ownersAgeRangeTo = otherTimeFrameFields.ownersAgeRangeTo + delta;

        return {
            ...otherTimeFrameFields,
            inflowYearsTo,
            yearsOfFlow,
            endDate,
            ownersAgeRangeTo
        }
    }

    calculateUsingInflowYearsFrom(inflowYearsFrom: number,
                                  otherTimeFrameFields: Omit<InflowTimeFrame, 'inflowYearsFrom'>,
                                  previousInflowYearsFrom: number): InflowTimeFrame {
        const delta = inflowYearsFrom - previousInflowYearsFrom;
        const deltaLowerBound = -1 * otherTimeFrameFields.yearsUntilFlow;
        const lowerBoundClampedDelta = Math.max(deltaLowerBound, delta);

        inflowYearsFrom = previousInflowYearsFrom + lowerBoundClampedDelta;
        let ownersAgeRangeFrom = otherTimeFrameFields.ownersAgeRangeFrom + lowerBoundClampedDelta;

        let yearsUntilFlow = otherTimeFrameFields.yearsUntilFlow + lowerBoundClampedDelta;

        const inflowYearsFromExceedsPlanningPeriod =
            yearsUntilFlow + otherTimeFrameFields.yearsOfFlow > this.investorGroup.planningPeriod.numberOfYears;

        if (inflowYearsFromExceedsPlanningPeriod) {
            inflowYearsFrom = previousInflowYearsFrom;
            ownersAgeRangeFrom = otherTimeFrameFields.ownersAgeRangeFrom;
            yearsUntilFlow = otherTimeFrameFields.yearsUntilFlow;
        }

        const inflowYearsTo = inflowYearsFrom + otherTimeFrameFields.yearsOfFlow;
        const ownersAgeRangeTo = ownersAgeRangeFrom + otherTimeFrameFields.yearsOfFlow;

        const startDate = calculateDateWithYear(otherTimeFrameFields.startDate!, inflowYearsFrom);
        const endDate = calculateDateWithYear(otherTimeFrameFields.endDate!, inflowYearsTo);

        return {
            ...otherTimeFrameFields,
            inflowYearsFrom,
            inflowYearsTo,
            startDate,
            endDate,
            yearsUntilFlow,
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        };
    }

    calculateUsingInflowYearsTo(inflowYearsTo: number,
                                otherTimeFrameFields: Omit<InflowTimeFrame, 'inflowYearsTo'>): InflowTimeFrame {
        let clampedInflowYearsTo = Math.max(inflowYearsTo, otherTimeFrameFields.inflowYearsFrom + 1);
        const today = moment();
        const startDate = moment(otherTimeFrameFields.startDate);

        const inflowYearsFrom = this.getInflowYearsFrom(today, startDate);
        let yearsOfFlow = clampedInflowYearsTo - inflowYearsFrom;
        const inflowYearsToExceedsPlanningPeriod =
            otherTimeFrameFields.yearsUntilFlow + yearsOfFlow > this.investorGroup.planningPeriod.numberOfYears;
        if (inflowYearsToExceedsPlanningPeriod) {
            yearsOfFlow = this.investorGroup.planningPeriod.numberOfYears - otherTimeFrameFields.yearsUntilFlow;
            clampedInflowYearsTo = otherTimeFrameFields.inflowYearsFrom + yearsOfFlow;
        }
        const endDate = calculateDateWithYear(otherTimeFrameFields.endDate!, clampedInflowYearsTo);
        const delta = yearsOfFlow - otherTimeFrameFields.yearsOfFlow
        const ownersAgeRangeTo = otherTimeFrameFields.ownersAgeRangeTo + delta;

        return {
            ...otherTimeFrameFields,
            yearsOfFlow,
            endDate,
            ownersAgeRangeTo,
            inflowYearsTo: clampedInflowYearsTo,
        };
    }

    calculateUsingOwnersAgeRangeFrom(ownersAgeRangeFrom: number,
                                     otherTimeFrameFields: Omit<InflowTimeFrame, 'ownersAgeRangeFrom'>,
                                     previousOwnersAgeRangeFrom: number) {

        const delta = ownersAgeRangeFrom - previousOwnersAgeRangeFrom;
        const inflowYearsFrom = otherTimeFrameFields.inflowYearsFrom + delta;

        const newTimeFrameFields = {
            ...otherTimeFrameFields,
            ownersAgeRangeFrom: previousOwnersAgeRangeFrom
        }

        return this.calculateUsingInflowYearsFrom(inflowYearsFrom, newTimeFrameFields, otherTimeFrameFields.inflowYearsFrom);
    }

    calculateUsingOwnersAgeRangeTo(ownersAgeRangeTo: number,
                                   otherTimeFrameFields: Omit<InflowTimeFrame, 'ownersAgeRangeTo'>,
                                   previousOwnersAgeRangeTo: number) {

        const delta = ownersAgeRangeTo - previousOwnersAgeRangeTo;
        const inflowYearsTo = otherTimeFrameFields.inflowYearsTo + delta;

        const newTimeFrameFields = {
            ...otherTimeFrameFields,
            ownersAgeRangeTo: previousOwnersAgeRangeTo
        }

        return this.calculateUsingInflowYearsTo(inflowYearsTo, newTimeFrameFields);
    }

    initialize(currentDate: Date, ownerMemberId: string) {
        const yearsUntilFlow = 0;
        const yearsOfFlow = 1;
        const today = moment(currentDate);
        const planningPeriodMemberBirthday = getBirthdateForOwnerWithLongerPlanningPeriod(this.investorGroup)

        const birthdayThisYear = moment(planningPeriodMemberBirthday, ISO8601_DATE_FORMAT).year(today.year());
        let lastBirthdayYear = birthdayThisYear.isSameOrBefore(today, 'day') ?
            birthdayThisYear : birthdayThisYear.year(birthdayThisYear.year() - 1);

        const startDate = lastBirthdayYear.add(yearsUntilFlow, 'year').format(ISO8601_DATE_FORMAT);
        const endDate = calculateDateWithYear(startDate, lastBirthdayYear.year() + yearsOfFlow);

        const inflowYearsFrom = extractYear(startDate);
        const inflowYearsTo = extractYear(endDate);

        const selectedOwnerBirthday = getBirthdateForSelectedOwner(this.investorGroup, ownerMemberId)
        const ownersAgeRangeFrom = inflowYearsFrom - extractYear(selectedOwnerBirthday!);
        const ownersAgeRangeTo = ownersAgeRangeFrom + yearsOfFlow;

        return {
            yearsOfFlow,
            yearsUntilFlow,
            startDate,
            endDate,
            inflowYearsFrom,
            inflowYearsTo,
            ownersAgeRangeFrom,
            ownersAgeRangeTo
        };
    }
}
