import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {NO_OP} from "../constants/common";
import {EstimatedImpact, initialEstimatedImpact, StrategyTimeFrame} from "./WealthPOTypes";

const AddEditGiftToGrantorTrust = () => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();

    // TODO: Add logic to this function when GTG estimated impact story comes up
    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return Promise.resolve(initialEstimatedImpact)
    }

    return (
        <StrategyWithTimeFrame profileId={id}
                               header={`${strategyId === undefined ? "Add" : "Edit"} Gift to Grantor Trust`}
                               strategyDetailsDescription={"Gift to Grantor Trust"}
                               strategyDetailsContributionAmount={0}
                               timeFrameYearsUntilFlow={0}
                               timeFrameYearsOfFlow={0}
                               getEstimatedImpact={getEstimatedImpact}
                               initialStateEstimatedImpact={initialEstimatedImpact}
                               handleSaveClick={NO_OP}
                               isAnnualGift={false}
        />)
}

export default AddEditGiftToGrantorTrust