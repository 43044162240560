import {PresentationPaneHeader} from "../../components";
import React from "react";
import ScrollableContainer from "src/components/ScrollableContainer/ScrollableContainer";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";

export const ComparePlans = () => {
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName


    return (
        <div className="plan-summary-page">
            <ScrollableContainer id={'compare-plans-scroll-container'}>
                <PresentationPaneHeader
                    displayName={displayName}
                    title="Compare Plans"
                />
            </ScrollableContainer>
            </div>
    )
}