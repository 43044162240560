import React, {useEffect} from "react";
import SetPortfolioReserveHeader from "./SetPortfolioReserveHeader";
import {formatCurrency, formatNumberRoundedToOneDecimal, formatYear} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    selectPortfolioReserveYears,
    setPortfolioReserveButtonStates,
    setPortfolioReserveYears
} from "./PortfolioReserveSlice";
import {COLOR_PORTFOLIO_RESERVE, COLOR_PROJECTED_ASSETS, COLOR_TRANSPARENT} from "../../constants/colors";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {Donut} from "xps-react";
import {portfolioReserveIsInvestablySufficient} from "../../Portfolio/portfolioUtils";
import {Button, Icon, InfoPopover, PresentationPaneHeader} from "../../components";
import AlignedInflowsTable from "./AlignedInflowsTable";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import {setHeaderTickerTabsProposedAllocation} from "../../ClientManagement/ClientProfile/HeaderTickerTabsSlice";
import {selectParticipatingInMeeting} from "../../ClientManagement/Meeting/meetingSlice";
import {useAppInsights} from "../../AppInsights";
import {MeetingParticipation} from "../../ClientManagement/Meeting/Meeting";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {LifestyleSpendingGoal, LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import classNames from "classnames";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import goalUtils from "../Summary/GoalUtils";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {handleTogglePortfolioReserve} from "./PortfolioReserveUtil";
import PrintViewWrapper from "../../Reporting/DetailedEmailReports/PrintViewWrapper";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {PortfolioReserveButtonState} from "./models/PortfolioReserveButtonState";


type ReviewPortfolioReserveProps = {
    errorModelData?: GenericErrorModalData,
    showTargetLabel?: boolean
    isReportingCall?: boolean
}

const ReviewPortfolioReserve = ({errorModelData, showTargetLabel=true, isReportingCall=false}: ReviewPortfolioReserveProps) => {
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const appInsights = useAppInsights();
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const handleErrorCloseButton = () => {
        errorModelData!.isOpen = false;
        setGenericError({...genericError, isOpen: false});
    }

    useEffect(() => {
        // Anything in here is fired on component mount.
        const startTime: number = new Date().valueOf();
        return () => {
            // Anything in here is fired on component unmount.
            const endTime: number = new Date().valueOf();
            appInsights.trackEvent({
                name: 'ReviewPortfolioReserve',
                properties:
                    {
                        screen: 'Review Portfolio Reserve',
                        action: 'Time Spent',
                        meetingStatus: getMeetingStatus(participatingInMeeting),
                        data: {
                            timeSpent: endTime - startTime // Gives the time Spent in milliseconds
                        }
                    }
            })
        }
    }, [])



    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const profileFromState = useAppSelector(selectProfile);
    const {minimumRiskInputYears} = useAppSelector(selectPortfolioReserveYears);
    const {
        portfolioReserve,
        profile,
        lifestyleSpendingGoal,
        assets,
        normalizedNonLifestyleGoals,
    } = portfolioReserveModel;
    const dispatch = useAppDispatch();
    const portfolioReserveButtons = useAppSelector(selectPortfolioReserveButtonStates);

    const reserveTargetAssetAllocation = {
        totalRiskControlAssetsPercent: portfolioReserve.adjustedTargetLengthAllocation.riskControlPercent,
        totalRiskAssetsPercent: portfolioReserve.adjustedTargetLengthAllocation.riskAssetPercent,
    }

    const fullPortfolioReserveTargetLength = getFullPortfolioReserveTargetLength(profile,portfolioReserve);

    const portfolioReserveTargetLength = getPortfolioReserveTargetLength(profile,fullPortfolioReserveTargetLength);

    const reserveTargetText = getReserveTargetText(profile);

    const portfolioSize = Math.max(assets.totalInvestableValue, (lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue + normalizedNonLifestyleGoals.nonLifestyleTotalPresentValue))

    const {
        portfolioReservePresentValue,
        portfolioReserveAlignedInflowPresentValue,
        reserveTargetAmount,
        inflowPresentValues
    } = lifestyleSpendingGoal.calculatedFields
    const portfolioIsInvestablySufficient = portfolioReserveIsInvestablySufficient({
        ...portfolioReserveModel,
        lifestylePresentValue: portfolioReserveModel.lifestyleSpendingGoal.calculatedFields,
        totalInvestableValue: assets.totalInvestableValue
    });


    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(reserveTargetAssetAllocation.totalRiskAssetsPercent, reserveTargetAssetAllocation.totalRiskControlAssetsPercent);

    useEffect(() => {
        //if minimum risk scenario had been updated on edit pr page, reset to default for next load
        if(minimumRiskInputYears != portfolioReserve.minimumRiskReserveLength){
            dispatch(setPortfolioReserveYears({
                prTargetInputYears: profile.portfolioReserveTargetLength != undefined ? profile.portfolioReserveTargetLength : portfolioReserve.portfolioReserveTargetLength,
                minimumRiskInputYears: portfolioReserve.minimumRiskReserveLength
            }));
        }
    })

    //rendering the page
    useEffect(() => {
        if (!profileFromState.isActivePortfolioReserve) {
            dispatch(setHeaderTickerTabsProposedAllocation(reserveTargetAssetAllocation));
        }
    }, [reserveTargetAssetAllocation]);

    const pages = getPages(isReportingCall,lifestyleSpendingGoal,portfolioReserveTargetLength);

    return (
        isReportingCall ?
            <>{pages.map((pageNumber) => {
                return (
                    <PrintViewWrapper displayName={profile.displayName} key={pageNumber}>
                        <div className="set-portfolio-reserve-page">
                            <div>
                                {errorModelData?.isOpen ?
                                    <GenericErrorModal
                                        errorModalData={errorModelData}
                                        onClickButton={() => handleErrorCloseButton()}
                                        buttonText={'Close'}/> : <React.Fragment/>
                                }
                            </div>
                            <ScrollableContainer id={'review-portfolio-reserve-page-scroll-container'} className="display-flex flex-column">
                                 <SetPortfolioReserveHeader
                                    displaySetReserveTargetButton={true}
                                    isSetReserve={false}
                                    onTogglePortfolioReserve={handleTogglePortfolioReserve}
                                 />
                                <div className="set-portfolio-reserve-page__body">
                                    <div className="set-portfolio-reserve-page__body__chart">
                                        <div className="review-portfolio-reserve-page__body__customized-allocation__header-container">
                                            <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header"
                                                text-align="left">
                                                {getProposedAssetAllocationText(pageNumber)}
                                            </h2>
                                            <h3 className="review-portfolio-reserve-page__body__customized-allocation__sub-header-text"
                                                style={{display: "block"}}> Investable Assets Only</h3>
                                        </div>
                                        <div className="set-portfolio-reserve-page__donut-container">
                                            <div className="set-portfolio-reserve-page__donut-container__solid">
                                                <RiskDonut
                                                    donutSize={'lg'}
                                                    data={reserveTargetAssetAllocation}
                                                    testId="RiskDonut"
                                                />
                                            </div>
                                            {portfolioIsInvestablySufficient &&
                                                <div className="set-portfolio-reserve-page__donut-container__shaded">
                                                    <div className={`donut-container donut-container__xl`}>
                                                        <Donut
                                                            role="risk-donut"
                                                            className="donut"
                                                            data-testid="PortfolioReserveDonut"
                                                            data={[
                                                                {value: (portfolioSize - portfolioReservePresentValue)},
                                                                {value: portfolioReservePresentValue},
                                                            ]}
                                                            colors={[COLOR_TRANSPARENT, COLOR_PORTFOLIO_RESERVE]}
                                                            outerRadius={60}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={"risk-assets-and-risk-control"} data-testid="RiskDonutLegend">
                                            <div className={"risk"}>
                                                <div className={"risk-legend-label"} data-testid="RiskLegendForRA">
                                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS}>
                                                        <span className={"risk-label-bold"}>Risk Assets</span>
                                                    </RiskLegend>
                                                    <span
                                                        className={"risk-percentage"}>{` ${roundedRiskAssetsPercent}%`}</span>
                                                </div>
                                            </div>
                                            <div className={"risk"}>
                                                <div className={"risk-legend-label"} data-testid="RiskLegendForRC">
                                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}>
                                                        <span className={"risk-label-bold"}>Risk Control</span>
                                                    </RiskLegend>
                                                    <span
                                                        className={"risk-percentage"}>{` ${roundedRiskControlAssetsPercent}%`}</span>
                                                </div>
                                            </div>
                                            <div className={"risk"}>
                                                <div className={"risk-legend-label"} data-testid="RiskLegendForPortfolioReserve">
                                                    <RiskLegend legendColor={riskLegendColor.PORTFOLIO_RESERVE}
                                                                label={"Portfolio Reserve"}/>
                                                </div>
                                            </div>
                                            {
                                                !portfolioIsInvestablySufficient &&
                                                <div data-testid="InvestableInsufficiencyWarning">
                                                    <div style={{marginTop: '4px'}}>
                                                        <InfoPopover
                                                            content={<div>Consider reducing your portfolio reserve target or aligning
                                                                goals
                                                                to non-investable assets in order to achieve investable
                                                                sufficiency.</div>}
                                                            direction="right"
                                                            width="288px"
                                                        />
                                                        <span
                                                            style={{color: COLOR_PROJECTED_ASSETS}}>
                                        Investable Asset Shortfall
                                    </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="review-portfolio-reserve-page__body__customized-allocation">

                                        {pageNumber === 1 && <> <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header">
                                            Review your current Portfolio Reserve
                                        </h2>
                                            <CustomizedAllocationTextForMLS text="Your annual lifestyle spending"
                                                                            spendingPeriods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                                                            portfolioReserveTargetLength={portfolioReserveTargetLength}/> </>}

                                        {((isReportingCall === true && pageNumber === 1 && getProtectedPeriods(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods,portfolioReserveTargetLength).length <= 5) || (isReportingCall === true && pageNumber === 2 && getProtectedPeriods(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods,portfolioReserveTargetLength).length > 5) ) && <><img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                                                                                                                                                                                                                                                                                                                                                                                                         src="/Rectangle679.svg"/>
                                            <CustomizedAllocationText text="The years of lifestyle spending you want to protect"
                                                                      quantity={formatYear(portfolioReserveTargetLength)}/>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">close</i>
                                            <div
                                                className="review-portfolio-reserve-page__body__customized-allocation__sub-text">{reserveTargetText}
                                            </div>
                                            {getEditPortfolioReserveBanner(showTargetLabel)}
                                            <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                 src="/Rectangle677.svg"/>
                                            <CustomizedAllocationText text="Your Reserve Target Amount"
                                                                      quantity={formatCurrency(reserveTargetAmount)}/>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                                            <span className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Calculated in Present Value</span>
                                            <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                 src="/Rectangle679.svg"/>
                                            <CustomizedAllocationText text="Future inflows you will receive over those years"
                                                                      quantity={formatCurrency(portfolioReserveAlignedInflowPresentValue)}>
                                                <Button kind="borderless"
                                                        size="small"
                                                        type="button"
                                                >{getPortfolioReserveButtons(portfolioReserveButtons)}</Button>
                                            </CustomizedAllocationText>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon review-portfolio-reserve-page__body__customized-allocation__minus-icon">–</i>
                                            {portfolioReserveButtons.futureInflow &&
                                                <AlignedInflowsTable
                                                    portfolioReserveTargetLength={portfolioReserveTargetLength}
                                                    inflowPresentValues={inflowPresentValues}
                                                />}
                                            <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                 src="/Rectangle677.svg"/>
                                            <CustomizedAllocationText text="Your Portfolio Reserve"
                                                                      quantity={formatCurrency(portfolioReservePresentValue)}
                                                                      tooltip={profileFromState.isActivePortfolioReserve && <InfoPopover
                                                                          content={<div style={{fontSize: "15px"}}>This value considers
                                                                              drawdown to risk control assets
                                                                              since turning on the Portfolio Reserve.</div>}
                                                                          direction="top"
                                                                          width="288px"
                                                                      />}/>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                                            <span className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Allocated to Risk Control Assets</span>
                                        </>}

                                    </div>
                                </div>
                            </ScrollableContainer>
                        </div>
                    </PrintViewWrapper>
                )
            })}
            </>
            : <>
                        <div className="set-portfolio-reserve-page">
                            <div>
                                {errorModelData?.isOpen &&
                                    <GenericErrorModal
                                        errorModalData={errorModelData}
                                        onClickButton={() => handleErrorCloseButton()}
                                        buttonText={'Close'}/>
                                }
                                {errorModelData?.isOpen === undefined && errorModelData?.isOpen === false && <React.Fragment/>}
                            </div>
                            <ScrollableContainer id={'review-portfolio-reserve-page-scroll-container'} className="display-flex flex-column">
                                <SetPortfolioReserveHeader
                                    displaySetReserveTargetButton={true}
                                    isSetReserve={false}
                                    onTogglePortfolioReserve={handleTogglePortfolioReserve}
                                />
                                <div className="set-portfolio-reserve-page__body">
                                    <div className="set-portfolio-reserve-page__body__chart">
                                        <div className="review-portfolio-reserve-page__body__customized-allocation__header-container">
                                            <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header"
                                                text-align="left">
                                                Proposed Asset Allocation
                                            </h2>
                                            <h3 className="review-portfolio-reserve-page__body__customized-allocation__sub-header-text"
                                                style={{display: "block"}}> Investable Assets Only</h3>
                                        </div>
                                        <div className="set-portfolio-reserve-page__donut-container">
                                            <div className="set-portfolio-reserve-page__donut-container__solid">
                                                <RiskDonut
                                                    donutSize={'lg'}
                                                    data={reserveTargetAssetAllocation}
                                                    testId="RiskDonut"
                                                />
                                            </div>
                                            {portfolioIsInvestablySufficient &&
                                                <div className="set-portfolio-reserve-page__donut-container__shaded">
                                                    <div className={`donut-container donut-container__xl`}>
                                                        <Donut
                                                            role="risk-donut"
                                                            className="donut"
                                                            data-testid="PortfolioReserveDonut"
                                                            data={[
                                                                {value: (portfolioSize - portfolioReservePresentValue)},
                                                                {value: portfolioReservePresentValue},
                                                            ]}
                                                            colors={[COLOR_TRANSPARENT, COLOR_PORTFOLIO_RESERVE]}
                                                            outerRadius={60}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={"risk-assets-and-risk-control"} data-testid="RiskDonutLegend">
                                            <div className={"risk"}>
                                                <div className={"risk-legend-label"} data-testid="RiskLegendForRA">
                                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS}>
                                                        <span className={"risk-label-bold"}>Risk Assets</span>
                                                    </RiskLegend>
                                                    <span
                                                        className={"risk-percentage"}>{` ${roundedRiskAssetsPercent}%`}</span>
                                                </div>
                                            </div>
                                            <div className={"risk"}>
                                                <div className={"risk-legend-label"} data-testid="RiskLegendForRC">
                                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}>
                                                        <span className={"risk-label-bold"}>Risk Control</span>
                                                    </RiskLegend>
                                                    <span
                                                        className={"risk-percentage"}>{` ${roundedRiskControlAssetsPercent}%`}</span>
                                                </div>
                                            </div>
                                            <div className={"risk"}>
                                                <div className={"risk-legend-label"} data-testid="RiskLegendForPortfolioReserve">
                                                    <RiskLegend legendColor={riskLegendColor.PORTFOLIO_RESERVE}
                                                                label={"Portfolio Reserve"}/>
                                                </div>
                                            </div>
                                            {
                                                !portfolioIsInvestablySufficient &&
                                                <div data-testid="InvestableInsufficiencyWarning">
                                                    <div style={{marginTop: '4px'}}>
                                                        <InfoPopover
                                                            content={<div>Consider reducing your portfolio reserve target or aligning
                                                                goals
                                                                to non-investable assets in order to achieve investable
                                                                sufficiency.</div>}
                                                            direction="right"
                                                            width="288px"
                                                        />
                                                        <span
                                                            style={{color: COLOR_PROJECTED_ASSETS}}>
                                        Investable Asset Shortfall
                                    </span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="review-portfolio-reserve-page__body__customized-allocation">

                                        <h2 className="review-portfolio-reserve-page__body__customized-allocation__sub-header">
                                            Review your current Portfolio Reserve
                                        </h2>
                                            <CustomizedAllocationTextForMLS text="Your annual lifestyle spending"
                                                                            spendingPeriods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                                                            portfolioReserveTargetLength={portfolioReserveTargetLength}/>

                                        {<><img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                                                                                                                                                                                                                                                                                                                                                                            src="/Rectangle679.svg"/>
                                            <CustomizedAllocationText text="The years of lifestyle spending you want to protect"
                                                                      quantity={formatYear(portfolioReserveTargetLength)}/>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">close</i>
                                            <div
                                                className="review-portfolio-reserve-page__body__customized-allocation__sub-text">{reserveTargetText}
                                            </div>
                                            {getEditPortfolioReserveBanner(showTargetLabel)}
                                            <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                 src="/Rectangle677.svg"/>
                                            <CustomizedAllocationText text="Your Reserve Target Amount"
                                                                      quantity={formatCurrency(reserveTargetAmount)}/>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                                            <span className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Calculated in Present Value</span>
                                            <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                 src="/Rectangle679.svg"/>
                                            <CustomizedAllocationText text="Future inflows you will receive over those years"
                                                                      quantity={formatCurrency(portfolioReserveAlignedInflowPresentValue)}>
                                                <Button kind="borderless"
                                                        size="small"
                                                        type="button"
                                                        onClick={() => {
                                                            dispatch(setPortfolioReserveButtonStates({
                                                                ...portfolioReserveButtons,
                                                                futureInflow: !portfolioReserveButtons.futureInflow
                                                            }));
                                                        }}>{getPortfolioReserveButtons(portfolioReserveButtons)}</Button>
                                            </CustomizedAllocationText>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon review-portfolio-reserve-page__body__customized-allocation__minus-icon">–</i>
                                            {portfolioReserveButtons.futureInflow &&
                                                <AlignedInflowsTable
                                                    portfolioReserveTargetLength={portfolioReserveTargetLength}
                                                    inflowPresentValues={inflowPresentValues}
                                                />}
                                            <img className="review-portfolio-reserve-page__body__customized-allocation__line-break"
                                                 src="/Rectangle677.svg"/>
                                            <CustomizedAllocationText text="Your Portfolio Reserve"
                                                                      quantity={formatCurrency(portfolioReservePresentValue)}
                                                                      tooltip={profileFromState.isActivePortfolioReserve && <InfoPopover
                                                                          content={<div style={{fontSize: "15px"}}>This value considers
                                                                              drawdown to risk control assets
                                                                              since turning on the Portfolio Reserve.</div>}
                                                                          direction="top"
                                                                          width="288px"
                                                                      />}/>
                                            <i className="dds-icons icon input__icon--large review-portfolio-reserve-page__body__customized-allocation__icon">=</i>
                                            <span className="review-portfolio-reserve-page__body__customized-allocation__sub-text">Allocated to Risk Control Assets</span>
                                        </>}

                                    </div>
                                </div>
                            </ScrollableContainer>
                        </div>
            </>
    );
};




const CustomizedAllocationText = ({children, quantity, text, tooltip}: {
    text: string,
    quantity: string,
    children?: React.ReactNode,
    tooltip?: React.ReactNode
}) => {
    return <div className="review-portfolio-reserve-page__body__customized-allocation__text">
        <div>{text}{tooltip ? tooltip : <></>}</div>

        <div className="quantity">{quantity}</div>
        {children ? children : <></>}
    </div>;
}

const EditPortfolioReserveBanner = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const handleEditPR = () => {
        history.push(`/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve`, {forceNavigate: true});
    }

    return (
        <div style={{paddingLeft: "15%", paddingRight: "15%"}} className={"paddingtop-lg"}>
            <div
                className="paddingtop-sm paddingright-sm display-flex paddingbottom-sm"
                style={{backgroundColor: "#f6f6f6"}}>
                <Icon
                    data-testid={"change-pr-icon"}
                    name={"info"}
                    type={"info"}
                    className={"paddingleft-md paddingtop-xs"}/>
                <div
                    className={"paddingleft-lg paddingright-xxxl display-flex font--small align-content-center"}
                    style={{fontWeight: "500", paddingTop: "3px"}}
                >
                    Looking to change the Reserve Target?
                </div>
                <div
                    className={"paddingleft-lg display-flex font--small align-content-center"}
                >
                <Button kind="borderless"
                        size="small"
                        type="button"
                        style={{borderColor: "transparent", whiteSpace:"pre"}}
                        onClick={handleEditPR}
                >
                    {"EDIT PORTFOLIO RESERVE"}
                </Button>
                </div>
            </div>
        </div>
    )
}

const CustomizedAllocationTextForMLS = ({spendingPeriods, portfolioReserveTargetLength, text}: {
    text: string,
    spendingPeriods: LifestyleSpendingPeriodInputs[],
    portfolioReserveTargetLength: number
}) => {

    if (portfolioReserveTargetLength === 0) {
        return <CustomizedAllocationText text={text}
                                         quantity={formatCurrency(spendingPeriods[0]?.annualSpend)}/>
    }


    const protectedPeriods = getProtectedPeriods(spendingPeriods,portfolioReserveTargetLength);

    if (protectedPeriods.length < 2) {
        return <CustomizedAllocationText text={text}
                                         quantity={formatCurrency(protectedPeriods[0]?.annualSpend)}/>
    }
    return <div className="review-portfolio-reserve-page__body__customized-allocation__text">
        <div>{text}</div>
        <div>
            {protectedPeriods.map((item: LifestyleSpendingPeriodInputs, index: number) => {
                    const lastItem = index === protectedPeriods.length - 1;
                    const roundedYears = Number(formatNumberRoundedToOneDecimal(item.endYear - item.startYear))
                    return <div
                        className="review-portfolio-reserve-page__body__customized-allocation__text__spending-period"
                        key={`${index}-${item.id}`}>
                        <div className="quantity">{formatCurrency(item.annualSpend)}</div>
                        <div
                            className={classNames(
                                "review-portfolio-reserve-page__body__customized-allocation__sub-text-years",
                                {
                                    "paddingbottom-12": !lastItem
                                }
                            )}>
                            {`${roundedYears} years`}
                        </div>
                    </div>;
                }
            )}
        </div>
    </div>
}

const getProtectedPeriods = (
    spendingPeriods: LifestyleSpendingPeriodInputs[],
    portfolioReserveTargetLength: number
) => {

    if (portfolioReserveTargetLength === 0) {
        return [];
    }

    const roundedActivatedPREndYear = Math.ceil(spendingPeriods[0].startYear + portfolioReserveTargetLength);
    const partialDrawdownYearAmount = Math.ceil(portfolioReserveTargetLength) - portfolioReserveTargetLength

    const protectedPeriods = spendingPeriods
        .filter((period) => period.startYear < roundedActivatedPREndYear)
        .map((period) => {
            return {
                ...period,
                endYear: Math.min(period.endYear, roundedActivatedPREndYear)
            }
        });
    if(protectedPeriods.length > 0) {
        protectedPeriods[0] = {
            ...protectedPeriods[0],
            startYear: spendingPeriods[0].startYear + partialDrawdownYearAmount
        }
    }
    return protectedPeriods;
}

const getFullPortfolioReserveTargetLength = (profile: ProfileResponse,portfolioReserve: PortfolioReserveResponse) => {
    return profile.portfolioReserveTargetLength ?? portfolioReserve.portfolioReserveTargetLength;
};

const getPortfolioReserveTargetLength = (profile: ProfileResponse,fullPortfolioReserveTargetLength:number) => {
    return profile.isActivePortfolioReserve ? goalUtils.getRemainingPortfolioReserveLength(profile.portfolioReserveTargetLength, profile.portfolioReserveActivationDate) : fullPortfolioReserveTargetLength;
};

const getReserveTargetText = (profile: ProfileResponse) => {
    return profile.isActivePortfolioReserve ? 'Expected Reserve Length' : 'Your Reserve Target Length'
};

const getMeetingStatus = (participatingInMeeting : boolean) => {
    return participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING
};

const getPages = (isReportingCall: boolean, lifestyleSpendingGoal: LifestyleSpendingGoal, portfolioReserveTargetLength: number) => {
    let pages: number[] = [1];
    if (isReportingCall === true && getProtectedPeriods(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods, portfolioReserveTargetLength).length > 5) {
        pages = [1, 2];
    }
    return pages;
};

const getProposedAssetAllocationText = (pageNumber : number) => {
    return pageNumber === 1 ? "Proposed Asset Allocation" : "Proposed Asset Allocation (continued)";
};

const getPortfolioReserveButtons = (portfolioReserveButtons : PortfolioReserveButtonState) => {
    return portfolioReserveButtons.futureInflow ? "Hide" : "Show"
};

const getEditPortfolioReserveBanner = (showTargetLabel:boolean) => {
    return showTargetLabel ? <EditPortfolioReserveBanner/> : <div/>;
}


export default ReviewPortfolioReserve;
