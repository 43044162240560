export const formatCurrency = (value: number = 0, minimumFractionDigits = 0) => {
    value = (value < 0 && value > -1) ? 0 : roundNumber(value)

    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits
    });
}
export const formatCurrencyFve = (value: number | string, minimumFractionDigits = 0) => {
    if (typeof value === "number") {
        value = (value < 0 && value > -1) ? 0 : roundNumber(value)
    }

    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits
    });
}

export function roundNumber(value: number): number {
    return Math.round(value);
}

export const truncateCurrency = (value: number = 0, {
    formatAsCurrency = true,
    includeUnits = true,
    maxValue,
}: {
    formatAsCurrency?: boolean;
    includeUnits?: boolean;
    maxValue?: number;
} = {}): string => {
    const exponentialBoundaryBase = 10;
    const totalDigits = 3;

    const wholeNumber = Number(String(value).split('.')[0]);
    const power = wholeNumber !== 0
        ? Math.ceil(Math.log(wholeNumber) / Math.log(exponentialBoundaryBase))
        : 1;

    let boundary = Math.max(Math.pow(exponentialBoundaryBase, power), 1);

    if (wholeNumber === boundary) {
        boundary *= exponentialBoundaryBase;
    }

    const scaledNumber = wholeNumber / boundary;
    const scale = (Math.round(Math.log(boundary) / Math.log(exponentialBoundaryBase)) % totalDigits) || totalDigits;
    const numFractionalDigits = totalDigits - scale;
    const truncatedNumber = Number(String(scaledNumber).padEnd(5, '0').substring(0, 5)) * Math.pow(exponentialBoundaryBase, scale);

    let suffix = '';
    if (includeUnits) {
        if (value === 0 && maxValue) {
            suffix = formatSuffix(maxValue);
        } else {
            suffix = formatSuffix(boundary);
        }
    }

    return `${
        formatAsCurrency
            ? truncatedNumber.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: numFractionalDigits
            })
            : String(truncatedNumber).split('.')[0]
    }${suffix}`;

}


const formatSuffix = (value: number) => {
    if (value > 1_000_000_000_000) {
        return 'T';
    } else if (value > 1_000_000_000) {
        return 'B';
    } else if (value > 1_000_000) {
        return 'M';
    } else if (value > 1_000) {
        return 'K';
    }
    return '';
}

export const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', {minimumFractionDigits: 2});
}

export const formatYear = (value: number) => {
    return value === 1 ? `${value} Year` : `${value} Years`;
}

export const formatPercentWithTwoDecimals = (value: number) => {
    const percentValue = value * 100;
    return percentValue.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + "%";
}

export const formatPercentWithWholeNumberAndTwoDecimals = (value: number) => {

    const percentValue = value * 100;
    return Number.isInteger(percentValue) ? percentValue + "%" : percentValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + "%";
}
export const formatPercentWithWholeNumberAndTwoDecimalsFve = (value: number | string) => {
    if (typeof value === "number") {

        const percentValue = value * 100;

        return percentValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) + "%";
    }
    else{
        return ""
    }
}

export const formatNumberRoundedToWholeNumber = (value: number) => {
    return formatNumberRounded(value, 0);
};

export const formatNumberRoundedToOneDecimal = (value: number) => {
    return formatNumberRounded(value, 1);
};

export const formatNumberRoundedToTwoDecimals = (value: number) => {
    return formatNumberRounded(value, 2);
};

export const formatCurrencyRoundedToTwoDecimals = (value: number) => {
    return formatCurrencyRounded(value, 2);
};

export const formatNumberToString = (value: number) => {
    return value.toLocaleString('en-US');
};

const formatNumberRounded = (value: number, fractionDigits: number) => {
    return value.toLocaleString('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    });
};

export const formatCurrencyRounded = (value: number, fractionDigits: number) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    });
};

export const formatCurrencyToNumber = (currency: string) => {
    return Number(currency.replace(/[$,]+/g, ""));
}


const countNumberOfDigits = (truncatedValueRoundedToTen: number) => {
    return String(truncatedValueRoundedToTen).match(/\d/g)?.length || 0;
};

export const roundUpToNext = (value: number, roundUpBy: number) => {
    const truncatedValue = Number(truncateCurrency(value, {
        formatAsCurrency: false,
        includeUnits: false,
    }));

    // Round up to the next roundUpBy value. If value falls exactly on a roundUpBy value boundary, round up to the next roundUpBy value
    const isEvenlyDivisibleByTheRoundUp = truncatedValue % roundUpBy === 0;
    const valueToRound = isEvenlyDivisibleByTheRoundUp
        ? truncatedValue + 1
        : truncatedValue;
    const truncatedValueRoundedToRoundUpByValue = Math.ceil(valueToRound / roundUpBy) * roundUpBy;

    const base = 10;
    const truncatedDigits = countNumberOfDigits(truncatedValue);
    const roundedDigits = countNumberOfDigits(truncatedValueRoundedToRoundUpByValue);
    const scaledValueAfterRounding = truncatedValueRoundedToRoundUpByValue / Math.pow(base, roundedDigits);

    const scaledDigits = countNumberOfDigits(Math.floor(value)) + (roundedDigits - truncatedDigits);
    return scaledValueAfterRounding * Math.max(Math.pow(base, scaledDigits), 1);
}