import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import useProfileAndProposals from "../hooks/useProfileAndProposals";

import {EmptyStateContainer, Icon, PresentationPaneHeader} from "../components";
import AddStrategyButtonDropdown from "./components/AddStrategyButtonDropdown";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import StrategySummarySection from "./components/StrategySummarySection";
import StrategyDetailsSection from "./components/StrategyDetailsSection";
import {COLOR_NT_AQUA_800} from "../constants/colors";
import {StrategyDetailsSectionInfo} from "./WealthPOTypes";

interface WealthPlanOptimizerContentProps {
    refreshStrategiesSummary: () => void;
    strategyDetailsList: StrategyDetailsSectionInfo[];
    isContinued: boolean;
    isInReportPreview: boolean;
}

const WealthPlanOptimizerContent: React.FC<WealthPlanOptimizerContentProps> = ({
                                                                                   refreshStrategiesSummary,
                                                                                   strategyDetailsList,
                                                                                   isContinued,
                                                                                   isInReportPreview
                                                                               }: WealthPlanOptimizerContentProps) => {
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;
    const hasStrategies = strategyDetailsList.length !== 0;

    return (
        <div className="wealth-plan-optimizer">
            <ScrollableContainer id={"wealth-plan-optimizer-scroll-container"}>
                <div className={hasStrategies ? "populated-page" : "empty-state-page"}>
                    <div id="wealth-plan-optimizer-header" data-testid="wealth-plan-optimizer-header">
                        <PresentationPaneHeader
                            displayName={displayName}
                            title={"Wealth Plan Optimizer"}
                            subtitle={isContinued ? "(continued)" : ""}
                            titleRow={<AddStrategyButtonDropdown className={'add-strategy-button-header'}/>}
                        />
                    </div>
                    {
                        !hasStrategies
                        && <div className={"no-strategy-container"}>
                            <EmptyStateContainer
                                className="no-strategy-container"
                                title="Begin by adding a strategy"
                                hideBorder={true}
                                description={<AddStrategyButtonDropdown className="add-strategy-dropdown"/>}
                            />
                        </div>
                    }
                    {
                        hasStrategies
                        && <div className={"wealthpo-summary-container"}
                                data-testid={"wealthpo-summary-container"}>
                            <StrategySummarySection/>
                            <StrategyDetailsSection refreshStrategiesSummary={refreshStrategiesSummary}
                                                    strategyDetailsList={strategyDetailsList}
                                                    isContinued={isContinued}
                                                    isInReportPreview={isInReportPreview}
                            />
                            <footer className="amount-to-beneficiaries-disclaimer">
                                <Icon name="info_outline" color={COLOR_NT_AQUA_800}/>
                                <span className="amount-to-beneficiaries-disclaimer-text">
                                    The "Amount to Beneficiaries" is assumed to be invested at the time of transfer and grows at the Risk Asset rate until the end of the planning period.
                                </span>
                            </footer>
                        </div>
                    }
                </div>
            </ScrollableContainer>
        </div>
    )
}

export default WealthPlanOptimizerContent;