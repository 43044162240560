import React, {useEffect, useState} from 'react';
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {Link, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";

import Highcharts from "highcharts";

import HighchartsReact from "highcharts-react-official";
import LoadingIndicator from 'src/pages/LoadingIndicator';


import {DataDisplayView} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";

import {
    emptyInvestorGroup,
    InvestorGroupMemberType,
    InvestorGroupType
} from 'src/ClientManagement/models/InvestorGroupType';
import {clientManagementApiClient} from 'src/ClientManagement/ClientManagementApiClient';
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";

import {AlertBanner, Button, EmptyStateContainer, Icon, PresentationPaneHeader} from "../../components";
import {FundingDisplayOptions, GoalFundingResponse} from "../../Goals/Funding/models/Funding";
import FundingService from "../../Goals/Funding/FundingService";
import {CreateFundingGraphOptions} from "../../Goals/Funding/FundingGraphUtils";
import FundingAgeInput from "../../Goals/Funding/FundingAgeInput";
import FundingSummary from "../../Goals/Funding/FundingSummary";
import PrintViewWrapper from "../DetailedEmailReports/PrintViewWrapper";
import FundingSummaryReport from "./FundingSummaryReport";
import {emptyProfileResponse, ProfileResponse} from "../../ClientManagement/models/ProfileResponse";

const GoalFundingWrapper = (props: {
    displayView: DataDisplayView,
    displayOptions: FundingDisplayOptions,
    fundingAgeToShowReport: number,
    setFundingAtAge?: (updatedFundingAtAge: number) => void,
    isEditPage: boolean,
}) => {
    const {id} = useParams<RouteWithId>();
    const { approvedProfile } = useProfileAndProposals(id);
    const { displayName } = approvedProfile;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fundingInfo, setFundingInfo] = useState<GoalFundingResponse>();
    const [fundingAge, setFundingAge] = useState<number>(0);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [profile, setProfile] = useState<ProfileResponse>(emptyProfileResponse);

    const pages : number[]= [1,2];


    const startAge = investorGroup.planningPeriod.ageFrom;
    const personalPlanningHorizon = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.ageFrom;
    const clientDisplayName = investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER
        ? investorGroup.partnerMember?.name!
        : investorGroup.primaryMember?.name!;
    const areGoalsAvailable: boolean = fundingInfo ? !!(fundingInfo.discretionary.goals.length ||
            fundingInfo.family.goalSummary.length ||
            fundingInfo.lifestyle.goals.length ||
            fundingInfo.philanthropic.goals.length)
        : false

    const isExcessShortfall: boolean = fundingInfo ? (fundingInfo.investableExcess.presentValueAtYear[0] <= 0 ||
        fundingInfo.investableExcess.presentValueAtYear.length === 0) : false;

    function loadGoalsFunding() {
        Promise.all([
            FundingService.getGoalFundingWithFamilyDetails(id),
            clientManagementApiClient.getInvestorGroup(id),
            clientManagementApiClient.getProfile(id)
        ]).then(([
                     fundingResponse, investorGroupResponse, profileResponse ]) => {
            setFundingInfo(fundingResponse);
            setInvestorGroup(investorGroupResponse);
            setProfile(profileResponse);
            setIsLoading(false);
        }).catch(error => console.error('Could not fetch funding details', error.message));
    }

    useEffect(() => {
        loadGoalsFunding();
    }, [id]);

    useEffect(() => {
        setFundingAge(startAge);
        if (props.isEditPage) {
            props.setFundingAtAge!(startAge);
        }
    }, [investorGroup])

    if (isLoading || !fundingInfo) {
        return <LoadingIndicator/>
    }

    const setFundingAtAge = (updatedFundingAtAge: number) => {
        setFundingAge(updatedFundingAtAge);
        if (props.isEditPage) {
            props.setFundingAtAge!(updatedFundingAtAge);
        }
    }

    const getFundingAgeToShowReport = () => {
       return props.fundingAgeToShowReport > 0 ? props.fundingAgeToShowReport : fundingAge;
    }

    const functionCallWhenGraphIsLoaded = () => {
        if (!props.isEditPage) {
            FundingService.getGoalFundingWithFamilyDetails(id);
        }
    }

    const EmptyGoalFunding = () => {
        return <EmptyStateContainer
            className="no-goal-funding-placeholder"
            data-testid="no-goal-funding-container"
            title="Begin by entering your goals on the goals summary page."
            hideBorder={true}
            description={
                <Link to={`/Profile/${id}/ClientProfile/Goals/Summary`}>
                    <Button
                        icon="left"
                        iconName="add"
                        kind="primary"
                        size="medium"
                        rounded
                    >Add Goal</Button>
                </Link>}
        />
    }

    const graphOptions: Highcharts.Options = CreateFundingGraphOptions({
        displayName: displayName,
        fundingAge: getFundingAgeToShowReport(),
        startAge: startAge,
        maxAge:personalPlanningHorizon,
        fundingInfo: fundingInfo,
        handleClickPointer: setFundingAtAge,
        isEmpty: !areGoalsAvailable,
        isShortfall: isExcessShortfall,
        functionCallWhenGraphIsLoaded:functionCallWhenGraphIsLoaded,
        isFunctionUsedForReportGeneration:true
    });

    const InvestableShortFallInfo = () => {
        return(
            <>
                {isExcessShortfall && areGoalsAvailable ? <div className="excess-shortfall-label">
                    <Icon name={`info`} color={`#BF5E00`} className={`info-icon`}/>
                    <span className={`subhead`}>Investable shortfall exists. Asset allocation may not be achievable.</span>
                </div> : <div></div> }
            </>
        );
    }

    return (
     props.isEditPage ? <>
             <ScrollableContainer id={'funding-page-scroll-container'} className="funding-page">
                 <AlertBanner
                     showAlert={profile.isActivePortfolioReserve}
                     icon="info"
                     type="warning"
                     fullWidth={false}
                     className="alert-pr-activated"
                     message={"The Portfolio Reserve is currently turned on. Lifestyle goal calculations do not reflect this drawdown to Risk Control."}
                 />
                 <div className={`finding-charts-section`}>
                     <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                     <InvestableShortFallInfo/>
                 </div>
                 {areGoalsAvailable && <>
                     <FundingAgeInput
                         fundingAtAge={getFundingAgeToShowReport()}
                         setFundingAtAge={setFundingAtAge}
                         clientDisplayName={clientDisplayName}
                         minAge={startAge}
                         maxAge={personalPlanningHorizon}
                     />
                     <FundingSummary
                         ageIndex={getFundingAgeToShowReport() - startAge}
                         startAge={startAge}
                         fundingInfo={fundingInfo}
                         displayOptions={props.displayOptions}
                         displayView={props.displayView}
                         isShortfall={isExcessShortfall}
                     />
                 </>}

                 {areGoalsAvailable === false && <EmptyGoalFunding/>}

             </ScrollableContainer>
      </> :
         <>
             {areGoalsAvailable && pages.map((pageNumber) => {
                 return (
                     <PrintViewWrapper displayName={displayName} key={pageNumber}>
                         <ScrollableContainer id={'funding-page-scroll-container'} className="funding-page">
                             <PresentationPaneHeader className={`goals-funding__header`}
                                                     displayName={""}
                                                     title={`Goal Funding Over Time${pageNumber > 1 ? ' (continued)' : ''}`}
                             />
                             {pageNumber === 1 &&
                                 <div>
                                     <AlertBanner
                                         showAlert={profile.isActivePortfolioReserve}
                                         icon="info"
                                         type="warning"
                                         fullWidth={false}
                                         className="alert-pr-activated"
                                         message={"The Portfolio Reserve is currently turned on. Lifestyle goal calculations do not reflect this drawdown to Risk Control."}
                                     />
                                     <div className={`finding-charts-section`}>
                                         <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                                         <InvestableShortFallInfo/>
                                     </div>
                                 </div>
                             }
                             <>
                                 {pageNumber === 1 && <FundingAgeInput
                                     fundingAtAge={getFundingAgeToShowReport()}
                                     setFundingAtAge={setFundingAtAge}
                                     clientDisplayName={clientDisplayName}
                                     minAge={startAge}
                                     maxAge={personalPlanningHorizon}
                                 />}
                                 <FundingSummaryReport
                                     ageIndex={getFundingAgeToShowReport() - startAge}
                                     startAge={startAge}
                                     fundingInfo={fundingInfo}
                                     displayOptions={props.displayOptions}
                                     displayView={props.displayView}
                                     pageNumber={pageNumber}
                                     isShortfall={isExcessShortfall}
                                 />
                             </>
                         </ScrollableContainer>
                     </PrintViewWrapper>
                 )
             })}


             {areGoalsAvailable === false &&
                 <PrintViewWrapper displayName={displayName} key={0}>
                     <div className={`finding-charts-section`}>
                         <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                     </div>
                     <EmptyGoalFunding/>
                 </PrintViewWrapper>
             }



         </>
    );
}

export default GoalFundingWrapper