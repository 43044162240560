import {Accounts, AssetsSummary, InvestmentProgramSummary} from "../../../Assets/models/Assets";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {
    calculateAllAccountsInEstateInvestableTotalValue,
    calculateAllAccountsInEstateTotalMarketValue,
    calculateInEstateEquityCompensationTotalValue, calculateInEstateInvestableTotalValueForAccounts,
    calculateInEstateLifeInsuranceTotalValue,
    calculatePersonalAssetInEstateTotalValue, calculateTotalValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {
    assetListData, getAllAccounts,
    hasInEstateAssets,
    sortByOrdinalAndAfterTaxVestedValue,
    sortByOrdinalAndCashValue,
    sortByOrdinalAndPresentValue
} from "../../../Assets/AssetSummary/common/utils";
import {AssetListHeader} from "../../../Assets/AssetSummary/common/AssetListHeader";
import {NO_OP} from "../../../constants/common";
import {Accordion, AccordionItemWithActionMenu} from "../../../components";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EQUITY_COMPENSATIONS,
    COLOR_FUTURE_INFLOWS,
    COLOR_LIFE_INSURANCES,
    COLOR_PERSONAL_ASSETS
} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import AccountsTable from "../../../Assets/AssetSummary/InEstate/AccountsTable";
import FutureInflowsTable from "../../../Assets/AssetSummary/InEstate/FutureInflowsTable";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {PersonalAssetRow} from "../../../Assets/AssetSummary/InEstate/PersonalAssetRow";
import {AssetSummaryPersonalAssetDetails} from "../../../Assets/AssetSummary/InEstate/AssetSummaryPersonalAssetDetails";
import {LifeInsurance} from "../../../Assets/models/LifeInsurance";
import {LifeInsuranceRow} from "../../../Assets/AssetSummary/InEstate/LifeInsuranceRow";
import {TableCell} from "../../../components/Table/TableCell";
import moment from "moment/moment";
import {mapToOwnershipDetailsFormData} from "../../../Assets/Ownership/mappers";
import {formatCurrency} from "../../../utils/format";
import React from "react";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "../../../Assets/models/PartiallyOwnedInvestmentAccount";
import EquityCompensationTableReport from "./EquityCompensationTableReport";
import {getCaption} from "./AssetsReportUtils";

type AssetReportListProps = {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    profileId: string,
    memberGroup?: MemberGroup,
    allAssets: AssetsSummary,
    isDetailed: boolean
};

export function AssetReportList({
                                    assetsDataForPage,
                                    investorGroup,
                                    profileId,
                                    memberGroup,
                                    allAssets,
                                    isDetailed
                                }: AssetReportListProps) {


    const {
        getFormattedTotalPresentValue,
        getCurrentOwnerByOwnerMemberId,
        getOwnerName,
        calculateTotalPresentValueForFutureInflows,
        getBeneficiariesList,
        hasFutureInflowAssets,
        hasInEstateAccount,
        hasInEstateInvestmentProgram,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        inEstatePersonalAssets,
        inEstateLifeInsurances,
        inEstateEquityCompensations,
        inEstateStandaloneAccounts,
        hasInEstateInvestmentProgramHeldAA
    } = assetListData(assetsDataForPage);

    return <section data-testid="in-estate-asset-summary">
        <div className="asset-list">
            <div className="accordion-tables">
                {hasInEstateAssets(assetsDataForPage) &&
                    <AssetListHeader columnTitles={["Asset Type", "Investable Value", "Present Value"]}
                                     onExpandedChanged={NO_OP}
                    />}
                <Accordion
                    accordionId={isDetailed ? "AssetSummaryAccordionReport" : "AssetSummaryCollapsedReport"}
                    allowMultipleExpanded allowZeroExpanded>
                    <div className="assets">
                        {(hasInEstateAccount || hasInEstateInvestmentProgram || hasInEstatePartiallyOwnedInvestmentAccounts || hasInEstateInvestmentProgramHeldAA) && <AccordionItemWithActionMenu
                            uuid="Accounts"
                            accentColor={COLOR_ASSETS_ACCOUNTS}
                            HeaderComponent={({expanded}) => {
                                const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                                    allAssets.accounts.data,
                                    allAssets.investmentProgram,
                                    allAssets.partiallyOwnedLegalAgreements);
                                const totalInEstateInvestableValue = calculateInEstateInvestableTotalValueForAccounts(
                                    allAssets.accounts.data,
                                    allAssets.investmentProgram?.legalAgreements || [],
                                    allAssets.investmentProgram?.heldAwayAccounts || []);
                                return <AssetAccordionHeader
                                    expanded={expanded}
                                    title={"Accounts"}
                                    caption={getCaption(isDetailed, getAllAccounts(allAssets)[0], assetsDataForPage.accounts.data[0])}
                                    formattedTotalInvestableValue={getFormattedTotalPresentValue(totalInEstateInvestableValue)}
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                                    gridClassName={"assets-grid"}
                                />;
                            }
                            }>
                            <AccountsTable
                                legalAgreements={assetsDataForPage.investmentProgram?.legalAgreements || []}
                                heldAwayAccounts={assetsDataForPage.investmentProgram?.heldAwayAccounts || []}
                                standaloneAccounts={inEstateStandaloneAccounts}
                                partiallyOwnedInvestmentAccounts={assetsDataForPage.partiallyOwnedLegalAgreements}
                                profileId={profileId}
                                onDeleteAssetClick={NO_OP}
                                captureAccountsOrder={NO_OP}
                            />
                        </AccordionItemWithActionMenu>}
                        {inEstateEquityCompensations.length > 0 && <AccordionItemWithActionMenu
                            uuid="EquityCompensations"
                            accentColor={COLOR_EQUITY_COMPENSATIONS}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title="Equity Compensation"
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculateInEstateEquityCompensationTotalValue(allAssets.equityCompensations.data))}
                                    gridClassName={"assets-grid"}
                                    customPresentValueLabel='After-Tax Total'
                                    caption={getCaption(isDetailed, sortByOrdinalAndAfterTaxVestedValue(allAssets.equityCompensations.data)[0], assetsDataForPage.equityCompensations.data[0])}

                                />
                            }
                        >
                            <div className="equity-compensation-section">
                                <EquityCompensationTableReport
                                    inEstateEquityCompensations_={inEstateEquityCompensations}
                                    editEquity_={NO_OP}
                                    deleteEquity_={NO_OP}
                                    memberGroup_={memberGroup}
                                />
                            </div>
                        </AccordionItemWithActionMenu>
                        }
                        {hasFutureInflowAssets && <AccordionItemWithActionMenu
                            uuid="FutureInflows"
                            accentColor={COLOR_FUTURE_INFLOWS}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title={"Future Inflows"}
                                    caption={getCaption(isDetailed,sortByOrdinalAndPresentValue(allAssets.generalInflows.data)[0] ,assetsDataForPage.generalInflows.data[0])}
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculateTotalPresentValueForFutureInflows(allAssets))}
                                    gridClassName={"assets-grid"}
                                />
                            }>
                            <div className="future-inflow-section">
                                <FutureInflowsTable
                                    generalInflows={assetsDataForPage.generalInflows}
                                    socialSecurities={assetsDataForPage.socialSecurities}
                                    investorGroup={investorGroup}
                                    onEditGeneralInflow={NO_OP}
                                    onEditSocialSecurity={NO_OP}
                                    onDeleteAssetClick={NO_OP}
                                    onSaveReviewTrustDisclosure={NO_OP}
                                />
                            </div>
                        </AccordionItemWithActionMenu>}
                        {inEstatePersonalAssets.length > 0 && <AccordionItemWithActionMenu
                            uuid="PersonalAssets"
                            accentColor={COLOR_PERSONAL_ASSETS}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title={"Personal Assets"}
                                    caption={getCaption(isDetailed, sortByOrdinalAndPresentValue(allAssets.personalAssets.data)[0], assetsDataForPage.personalAssets.data[0])}
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculatePersonalAssetInEstateTotalValue(allAssets.personalAssets.data))}
                                    gridClassName={"assets-grid"}
                                />
                            }>
                            <div role="table" className="assets-grid-with-actionmenu assets-grid-table"
                                 aria-label="personal-assets-table">
                                <div role="row" className="assets-grid-with-actionmenu assets-grid-table-header" tabIndex={-1}>
                                    <span role="columnheader"
                                          className='grid-span-4 paddingleft-xxl textalign-left'>Name</span>
                                    <span role="columnheader" className='textalign-right'>Present Value</span>
                                </div>
                                {inEstatePersonalAssets.map((personalAsset: PersonalAsset) => {
                                    return <PersonalAssetRow personalAsset={personalAsset}
                                                             key={personalAsset.id}
                                                             renderPersonalAssetDetails={(personalAssetData: PersonalAsset) => {
                                                                 return <AssetSummaryPersonalAssetDetails
                                                                     personalAsset={personalAssetData}/>
                                                             }}
                                                             actionsDisabled={false}
                                                             gridClassName={"assets-grid-with-actionmenu"}
                                                             onClickEdit={NO_OP}
                                                             onClickDelete={NO_OP}/>;
                                })}
                            </div>
                        </AccordionItemWithActionMenu>}
                        {inEstateLifeInsurances.length > 0 && <AccordionItemWithActionMenu
                            uuid="LifeInsurances"
                            accentColor={COLOR_LIFE_INSURANCES}
                            HeaderComponent={({expanded}) =>
                                <AssetAccordionHeader
                                    expanded={expanded}
                                    title={"Life Insurance"}
                                    caption={getCaption(isDetailed,sortByOrdinalAndCashValue(allAssets.lifeInsurances.data)[0],assetsDataForPage.lifeInsurances.data[0])}
                                    formattedTotalPresentValue={getFormattedTotalPresentValue(calculateTotalValue(allAssets.lifeInsurances.data))}
                                    gridClassName={"assets-grid"}
                                />
                            }>
                            <div className="life-insurance-section">
                                <div role="table" className="assets-grid-with-actionmenu assets-grid-table"
                                     aria-label="life-insurance-table">
                                    <div role="row" className="assets-grid-with-actionmenu assets-grid-table-header" tabIndex={-1}>
                                        <span className='textalign-left' role="columnheader">Name</span>
                                        <span className='textalign-left' role="columnheader">Company & Owner</span>
                                        <span className='textalign-left'
                                              role="columnheader">Insured & Beneficiary</span>
                                        <span className='textalign-right' role="columnheader">Death Benefit</span>
                                        <span className='textalign-right' role="columnheader">Cash Value</span>
                                    </div>
                                    {inEstateLifeInsurances.findIndex(lifeInsurance => (lifeInsurance.isCashValueWillFundGoals)) !== -1 &&
                                        <div role="row" className="grid-display-contents asset-row-subheader">
                                            <span role="cell" aria-label={'include-in-asset'}
                                                  className="textalign-left grid-span-5 fontweight-medium">Included in Net Assets</span>
                                        </div>}
                                    {inEstateLifeInsurances.filter(lifeInsurance => (lifeInsurance.isCashValueWillFundGoals)).map((inEstateLifeInsurance: LifeInsurance) => {
                                        return <LifeInsuranceRow lifeInsurance={inEstateLifeInsurance}
                                                                 key={inEstateLifeInsurance.id}
                                                                 gridClassName={"assets-grid-with-actionmenu"}
                                                                 renderLifeInsuranceDetails={(insurance: LifeInsurance) => {
                                                                     return <>
                                                                         <TableCell text={insurance.description}
                                                                                    subtext={`Issued ${(insurance.issueDate) ? moment(insurance.issueDate).format('MM/DD/YYYY') : '—'}`}/>
                                                                         <TableCell
                                                                             text={insurance.insuranceCompanyName}
                                                                             subtext={getOwnerName(mapToOwnershipDetailsFormData(insurance).memberOwnerships, memberGroup!)}
                                                                             className="textalign-left"/>
                                                                         <TableCell
                                                                             text={getCurrentOwnerByOwnerMemberId(insurance.insuredMemberId, memberGroup!).firstName}
                                                                             subtext={getBeneficiariesList(insurance.beneficiaries, memberGroup!)}
                                                                             className="textalign-left"/>
                                                                         <TableCell
                                                                             text={formatCurrency(insurance.deathBenefitValue)}
                                                                             className="textalign-right"/>
                                                                     </>
                                                                 }}
                                                                 actionsDisabled={false}
                                                                 onClickEdit={NO_OP}
                                                                 onClickDelete={NO_OP}/>;
                                    })}

                                    {inEstateLifeInsurances.findIndex(insurance => (!insurance.isCashValueWillFundGoals)) !== -1 &&
                                        <div role="row" className="grid-display-contents asset-row-subheader">
                                            <span role="cell" aria-label={'exclude-from-asset'}
                                                  className="textalign-left grid-span-5 fontweight-medium">Exclude from Net Assets</span>
                                        </div>}
                                    {inEstateLifeInsurances.filter(lifeInsurance => (!lifeInsurance.isCashValueWillFundGoals)).map((lifeInsuranceData: LifeInsurance) => {
                                        return <LifeInsuranceRow lifeInsurance={lifeInsuranceData}
                                                                 key={lifeInsuranceData.id}
                                                                 gridClassName={"assets-grid-with-actionmenu"}
                                                                 renderLifeInsuranceDetails={(lifeInsuranceDetails: LifeInsurance) => {
                                                                     return <>
                                                                         <TableCell
                                                                             text={lifeInsuranceDetails.description}
                                                                             subtext={`Issued ${(lifeInsuranceDetails.issueDate) ? moment(lifeInsuranceDetails.issueDate).format('MM/DD/YYYY') : '—'}`}/>
                                                                         <TableCell
                                                                             text={lifeInsuranceDetails.insuranceCompanyName}
                                                                             subtext={getOwnerName(mapToOwnershipDetailsFormData(lifeInsuranceDetails).memberOwnerships, memberGroup!)}
                                                                             className="textalign-left"/>
                                                                         <TableCell
                                                                             text={getCurrentOwnerByOwnerMemberId(lifeInsuranceDetails.insuredMemberId, memberGroup!).firstName}
                                                                             subtext={getBeneficiariesList(lifeInsuranceDetails.beneficiaries, memberGroup!)}
                                                                             className="textalign-left"/>
                                                                         <TableCell
                                                                             text={formatCurrency(lifeInsuranceDetails.deathBenefitValue)}
                                                                             className="textalign-right"/>
                                                                     </>
                                                                 }}
                                                                 actionsDisabled={false}
                                                                 onClickEdit={NO_OP}
                                                                 onClickDelete={NO_OP}/>;
                                    })}

                                </div>
                            </div>
                        </AccordionItemWithActionMenu>}
                    </div>
                </Accordion>
            </div>
        </div>
    </section>;
}