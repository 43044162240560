import React, {ChangeEvent, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectDisplayName} from "../ClientProfile/approvedProfileSlice";
import {
    AlertBanner,
    Button,
    DropdownItem,
    EmptyStateContainer,
    PageActionMenu,
    PresentationPaneHeader
} from "../../components";
import {DropdownGroup} from "xps-react";
import ClickableYearsInput, {IconState} from "../../components/YearsInput/ClickableYearsInput";
import {emptyInvestorGroup, InvestorGroupType} from "../models/InvestorGroupType";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {AssetsSummary, emptyFutureValueOfExcessAssets, FutureValueOfExcessAssetsDTO} from "../../Assets/models/Assets";
import {formatCurrency, formatPercentWithWholeNumberAndTwoDecimals} from "../../utils/format";
import {
    calculateCNWInEstateTotalValue,
    calculateInEstateFormValueForLifeInsurance
} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {createGraphOptions} from "./NetWorthOverTimeGraphUtils";
import {FveDiscountRateType} from "../AssetReliance/AssetRelianceButtonState";
import {NetWorthOverTimeGoalsAchievedTable} from "./NetWorthOverTimeGoalsAchievedTable";
import {goalsApiClient} from "../../Goals/GoalsApiClient";
import {AllGoalsType} from "../../Goals/models/GoalModelType";
import {GoalType, NonLifestyleGoalType} from "../../Goals/models/GoalType";
import {LifestyleSpendingPeriodInputs} from "../../Goals/models/LifestyleSpendingGoal";
import GoalUtils from "../../Goals/Summary/GoalUtils";
import FamilyGoalUtils from "../../Goals/Summary/FamilyGoalUtils";
import {LifeInsurance} from "../../Assets/models/LifeInsurance";


const NetWorthOverTime: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const displayName = useAppSelector(selectDisplayName) ?? "";
    const [futureValueOfExcessAssetsDTO, setFutureValueOfExcessAssetsDTO] = useState<FutureValueOfExcessAssetsDTO>(emptyFutureValueOfExcessAssets);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {id} = useParams<RouteWithId>();
    const [projectedNetWorthAge, setProjectedNetWorthAge] = useState<number>();
    const [confirmedProjectedNetWorthAge, setConfirmedProjectedNetWorthAge] = useState<number>();
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const [discountRate, setDiscountRate] = useState<FveDiscountRateType>(FveDiscountRateType.TRIPLE_NET);
    const [includeLifeInsurance, setIncludeLifeInsurance] = useState<boolean>(false);
    const [goals, setGoals] = useState<AllGoalsType>();

    let redrawPlotLineTooltip: boolean = true;

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            assetsApiClient.getFutureValueOfExcessAssets(id),
            assetsApiClient.getAssetsSummary(id),
            clientManagementApiClient.getInvestorGroup(id),
            goalsApiClient.getAllGoals(id),
        ]).then(([
                     futureValueOfExcessAssetsResponse,
                     assetsSummaryResponse,
                     investorGroupResponse,
                     goalsResponse,
                 ]) => {
            dispatch(setClientAssets(assetsSummaryResponse));
            setFutureValueOfExcessAssetsDTO(futureValueOfExcessAssetsResponse);
            setInvestorGroup(investorGroupResponse);
            setProjectedNetWorthAge(investorGroupResponse.planningPeriod.numberOfYears + investorGroupResponse.planningPeriod.ageFrom);
            setConfirmedProjectedNetWorthAge(investorGroupResponse.planningPeriod.numberOfYears + investorGroupResponse.planningPeriod.ageFrom);
            setGoals(goalsResponse);
            setIsLoading(false);
        }).catch(error => console.error('Could not fetch asset details', error.message));
    }, [id])

    const personalPlanningHorizon = investorGroup.planningPeriod.numberOfYears + investorGroup.planningPeriod.ageFrom;
    const handleDecreaseYear = () => {
        if (projectedNetWorthAge! > investorGroup.planningPeriod.ageFrom) {
            setConfirmedProjectedNetWorthAge(projectedNetWorthAge! - 1);
            setProjectedNetWorthAge(projectedNetWorthAge! - 1);
            redrawPlotLineTooltip = true;
        }
    }

    const handleIncreaseYear = () => {
        if (projectedNetWorthAge! < personalPlanningHorizon) {
            setConfirmedProjectedNetWorthAge(projectedNetWorthAge! + 1);
            setProjectedNetWorthAge(projectedNetWorthAge! + 1);
            redrawPlotLineTooltip = true;
        }
    }

    const handleTargetProjectedNetWorthAgeBlur = (updatedAge: number) => {
        if (isNaN(updatedAge) || updatedAge > personalPlanningHorizon) {
            setConfirmedProjectedNetWorthAge(personalPlanningHorizon);
            setProjectedNetWorthAge(personalPlanningHorizon);
        } else if (updatedAge < investorGroup.planningPeriod.ageFrom) {
            setConfirmedProjectedNetWorthAge(investorGroup.planningPeriod.ageFrom);
            setProjectedNetWorthAge(investorGroup.planningPeriod.ageFrom);
        } else {
            setConfirmedProjectedNetWorthAge(updatedAge);
            setProjectedNetWorthAge(updatedAge);
        }
        redrawPlotLineTooltip = true;
    }

    const handleTargetProjectedNetWorthAge = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
        setProjectedNetWorthAge(value)
    }

    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const totalLifeInsuranceCashValue = calculateTotalValue(clientAssets!.lifeInsurances.data);

    function calculateTotalValue(lifeInsurances: LifeInsurance[]): number {
        return lifeInsurances
            .reduce((previousValue, currentValue) => {
                const lifeInsuranceValue = !includeLifeInsurance && !currentValue.isCashValueWillFundGoals ? currentValue.cashValue : 0;
                return previousValue + calculateInEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships);
            }, 0);
    }
    const netWealthAtCurrentAge = calculateCNWInEstateTotalValue(clientAssets!, includeLifeInsurance) + totalTaxLiabilitiesForAllAccounts - totalLifeInsuranceCashValue ;

    const showProjectedNetWorthAtAge = () => {
        const selectedAgeIsCurrentAge: boolean = (
            confirmedProjectedNetWorthAge === undefined
            || confirmedProjectedNetWorthAge === investorGroup.planningPeriod.ageFrom
        );

        if (
            clientAssets && selectedAgeIsCurrentAge
        ) {
            return formatCurrency(netWealthAtCurrentAge);
        }

        let useLifeInsuranceValues: string = includeLifeInsurance ? "yesLifeInsurance" : "noLifeInsurance";
        let finalNetWorthReference: {
            [discountRate in FveDiscountRateType]: {
                [lifeInsuranceStatus: string]: string
            }
        } = {
            [FveDiscountRateType.TRIPLE_NET]: {
                noLifeInsurance: formatCurrency(futureValueOfExcessAssetsDTO.futureValueOfExcessAssetsByAgeWithTNDR[confirmedProjectedNetWorthAge! - investorGroup.planningPeriod.ageFrom - 1]),
                yesLifeInsurance: formatCurrency(futureValueOfExcessAssetsDTO.fveWithLifeInsuranceDeathValueByAgeTNDR[confirmedProjectedNetWorthAge! - investorGroup.planningPeriod.ageFrom - 1]),
            },
            [FveDiscountRateType.NOMINAL_NET]: {
                noLifeInsurance: formatCurrency(futureValueOfExcessAssetsDTO.futureValueOfExcessAssetsByAgeWithNNDR[confirmedProjectedNetWorthAge! - investorGroup.planningPeriod.ageFrom - 1]),
                yesLifeInsurance: formatCurrency(futureValueOfExcessAssetsDTO.fveWithLifeInsuranceDeathValueByAgeNNDR[confirmedProjectedNetWorthAge! - investorGroup.planningPeriod.ageFrom - 1]),
            },
        };
        return finalNetWorthReference[discountRate][useLifeInsuranceValues] || 0;
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const handleFveDiscountRate = (selectedFveDiscountRate: FveDiscountRateType) => {
        setDiscountRate(selectedFveDiscountRate);
    }
    const handleClickPointer = (age: number | string) => {
        setConfirmedProjectedNetWorthAge(Number(age))
        setProjectedNetWorthAge(Number(age))
    }

    function getNonLifestyleGoalsAnnualFlow(nonLifestyleGoals: NonLifestyleGoalType[]) {
        const planningPeriodNumberOfYears = investorGroup.planningPeriod.numberOfYears;
        const annualFlowByYearForEachGoal: number[][] = nonLifestyleGoals
            .filter(nonLifestyleGoalType => nonLifestyleGoalType.userInputs.isAchieved == null && !nonLifestyleGoalType.userInputs.isAchieved)
            .map(nonLifestyleGoal => {
                const {
                    yearsOfFlow,
                    yearsUntilFlow
                } = GoalUtils.getYearsOfFlowAndYearsUntilFlow(nonLifestyleGoal, investorGroup);
                const paddedZeroesUntilGoalStarts: number[] = new Array(yearsUntilFlow).fill(0);
                const annualFlowYears: number[] = new Array(yearsOfFlow).fill(nonLifestyleGoal.userInputs.annualFlow);
                const zeroesUntilEndOfPlanningPeriod: number[] = new Array(planningPeriodNumberOfYears - (yearsOfFlow + yearsUntilFlow)).fill(0);

                return [
                    ...paddedZeroesUntilGoalStarts,
                    ...annualFlowYears,
                    ...zeroesUntilEndOfPlanningPeriod
                ];
            });

        const sumOfGoalsByYear: number[] = [];
        for (let year = 0; year < planningPeriodNumberOfYears; year++) {
            sumOfGoalsByYear.push(
                annualFlowByYearForEachGoal.reduce((sum, goal) => {
                    return sum + goal[year];
                }, 0)
            );
        }
        return sumOfGoalsByYear;
    }

    function getFamilyGoalsAnnualFlow() {
        const familyGoals = Object.values(goals!.family)
            .flatMap(familyGoal => Object.values(familyGoal.goals))
            .map(familyGoalType => FamilyGoalUtils.convertFamilyGoalTypeToNonLifestyleGoalType(familyGoalType));
        return getNonLifestyleGoalsAnnualFlow(familyGoals);
    }

    function getDiscretionaryGoalsAnnualFlow() {
        const discretionaryGoals = Object.values(goals!.nonLifestyle)
            .filter(goal => goal.goalType === GoalType.DISCRETIONARY);
        return getNonLifestyleGoalsAnnualFlow(discretionaryGoals);
    }

    function getPhilanthropicGoalsAnnualFlow() {
        const philanthropicGoals = Object.values(goals!.nonLifestyle)
            .filter(goal => goal.goalType === GoalType.PHILANTHROPIC);
        return getNonLifestyleGoalsAnnualFlow(philanthropicGoals);
    }

    function getLifestyleAnnualFlow() {
        const lifestyleGoalNotExists = goals!.lifeStyle === undefined
        const planningPeriodNumberOfYears = investorGroup.planningPeriod.numberOfYears;
        if (lifestyleGoalNotExists) {
            return new Array(planningPeriodNumberOfYears).fill(0);
        }

        const lifestyleSpendingPeriods = goals!.lifeStyle!.userInputs!.lifestyleSpendingPeriods;
        lifestyleSpendingPeriods.sort((a: LifestyleSpendingPeriodInputs, b: LifestyleSpendingPeriodInputs) => a.startYear - b.startYear);

        return lifestyleSpendingPeriods
            .flatMap(lifestyleSpendingPeriodInputs => {
                    return new Array(lifestyleSpendingPeriodInputs.endYear - lifestyleSpendingPeriodInputs.startYear)
                        .fill(lifestyleSpendingPeriodInputs.annualSpend)
                }
            );
    }


    const graphOptions: Highcharts.Options = createGraphOptions({
        investorGroup,
        projectedNetWorthAge: confirmedProjectedNetWorthAge,
        redrawPlotLineTooltip,
        netWealthAtCurrentAge,
        futureValueOfExcessAssetsDTO,
        discountRate,
        includeLifeInsurance,
        handleClickPointer: handleClickPointer
    });

    Highcharts.setOptions({
        lang: {
            numericSymbols: ['K', ' M', 'B', 'T', 'P', 'E'],
            thousandsSep: ""
        }
    });

    const pageActionMenu = () => (
        <div>
            <PageActionMenu
                className="show-net-worth-over-time-options three-dots-menu"
                panelHeight="auto"
                panelWidth={280}
                aria-label="show-net-worth-over-time-options"
                key={"page-action-menu"}
            >
                <DropdownGroup groupName=" " key={"ls-dropdown-group"} show={true}>
                    <DropdownItem className="net-worth-over-time-page-action-menu-options" value="" itemText="">
                        <div className="life-insurance-value-at-death-option">
                            <div>Include Life Insurance at Death</div>
                            <input
                                id="includeLifeInsuranceAtDeath"
                                data-testid="include-life-insurance-at-death"
                                name="include-life-insurance-at-death"
                                type="checkbox"
                                aria-label="include-life-insurance-at-death"
                                key={"include-life-insurance-at-death"}
                                onClick={(e) => {
                                    setIncludeLifeInsurance(!includeLifeInsurance);
                                    e.stopPropagation();
                                }}
                                disabled={!(clientAssets && clientAssets.lifeInsurances && clientAssets.lifeInsurances.data.length > 0)}
                            />
                        </div>
                    </DropdownItem>
                </DropdownGroup>
                <DropdownGroup groupName="Growth Rate" key="discount-rate-dropdown-group">
                    <DropdownItem key="triple-net-discount-rate" data-testid="triple-net-discount-rate-option"
                                  value={""} itemText={""}>
                        <div style={{width: "100%"}} className="net-worth-over-time-page-action-menu-options"
                             onClick={(e) => {
                                 handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)
                                 e.stopPropagation();
                             }}>
                            Triple Net Growth Rate
                            <input name="triple-net-discount"
                                   type="radio" value='tndr'
                                   aria-label="triple-net-discount-option"
                                   data-testid="triple-net-discount-rate-option-input"
                                   checked={discountRate === FveDiscountRateType.TRIPLE_NET}
                                   onChange={(e) => {
                                       handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)
                                       e.stopPropagation()
                                   }}
                            />
                        </div>
                    </DropdownItem>
                    <DropdownItem key="nominal-net-discount-rate" data-testid="nominal-net-discount-rate-option"
                                  value={""} itemText={""}>
                        <div style={{width: "100%"}} className="net-worth-over-time-page-action-menu-options"
                             onClick={(e) => {
                                 handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)
                                 e.stopPropagation();
                             }}>
                            Nominal Net Growth Rate
                            <input name="nominal-net-discount"
                                   type="radio" value='nndr'
                                   aria-label="nominal-net-discount-option"
                                   data-testid="nominal-net-discount-rate-option-input"
                                   checked={discountRate === FveDiscountRateType.NOMINAL_NET}
                                   onChange={(e) => {
                                       handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)
                                       e.stopPropagation();
                                   }}
                            />
                        </div>
                    </DropdownItem>
                </DropdownGroup>
            </PageActionMenu>
        </div>
    )

    const displayedDiscountRateType = discountRate === FveDiscountRateType.TRIPLE_NET ? "Triple Net Growth Rate" : "Nominal Net Growth Rate";
    const displayedDiscountRate = formatPercentWithWholeNumberAndTwoDecimals(discountRate === FveDiscountRateType.TRIPLE_NET ? futureValueOfExcessAssetsDTO.displayedDiscountRateWithTNDR : futureValueOfExcessAssetsDTO.displayedDiscountRateWithNNDR);

    const familyGoalsAnnualFlowByYear: number[] = getFamilyGoalsAnnualFlow();
    const discretionaryGoalsAnnualFlowByYear: number[] = getDiscretionaryGoalsAnnualFlow();
    const philanthropicGoalsAnnualFlowByYear: number[] = getPhilanthropicGoalsAnnualFlow();
    const lifestyleGoalsAnnualFlowByYear: number[] = getLifestyleAnnualFlow();
    const hasExcessAssets: boolean = (clientAssets!.totalNetValue + goals!.totalAssetPurchasesPresentValue + clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts! - goals!.totalGoalsPresentValue) >= 0;
    const navigateToAssetReliance = () => {
        history.push(`/Profile/${id}/ClientProfile/AssetReliance`);
    }

    return (
        hasExcessAssets ?
            <div className="net-worth-over-time">
                <div id="net-worth-over-time-header">
                    <PresentationPaneHeader displayName={displayName} title={"Net Worth Over Time"}
                                            titleRow={pageActionMenu()}/>
                    <AlertBanner type={"info"} showAlert={includeLifeInsurance}>This includes the value of the death
                        benefit of your Life Insurance policy, which may be included in your taxable
                        estate.</AlertBanner>
                    <span className="net-worth-over-time__projected-net-worth font-xl">
               Your projected net worth at &nbsp;
                        <ClickableYearsInput
                            name={"projectedNetWorthAge"}
                            onUpArrowClick={handleIncreaseYear}
                            onDownArrowClick={handleDecreaseYear}
                            onChangeValue={handleTargetProjectedNetWorthAge}
                            onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTargetProjectedNetWorthAgeBlur(Number(e.target.value))}
                            value={projectedNetWorthAge}
                            inputStyle={{
                                textAlign: "right",
                                paddingRight: "15%",
                            }}
                            inputClassName={"net-worth-over-time__age-input editable-age-border"}
                            upArrowClassName={"dds-icons input__icon input__icon__increase-support-button"}
                            downArrowClassName={"dds-icons input__icon input__icon__decrease-support-button"}
                            disabledAndReadOnly={false}
                            iconState={IconState.ARROWS}
                            inlineLabel={"age"}
                            inlineLabelAlign={"left"}
                        />&nbsp; is &nbsp;<span className="font-xxl">{showProjectedNetWorthAtAge()}</span>
                </span>
                    <span className="net-worth-over-time__triple-net-discount-rate">
                    <b>{displayedDiscountRateType}</b>
                    <span
                        className="net-worth-over-time__triple-net-discount-rate_value">{displayedDiscountRate}</span>
                </span>
                </div>
                <div className="net-worth-over-time-body">
                    <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                    <NetWorthOverTimeGoalsAchievedTable
                        selectedAge={confirmedProjectedNetWorthAge!}
                        planningPeriodAgeFrom={investorGroup.planningPeriod.ageFrom}
                        discretionaryGoalValues={discretionaryGoalsAnnualFlowByYear}
                        familyGoalValues={familyGoalsAnnualFlowByYear}
                        philanthropicGoalValues={philanthropicGoalsAnnualFlowByYear}
                        lifestyleGoalValues={lifestyleGoalsAnnualFlowByYear}
                    />
                </div>
            </div>
            : <div className="net-worth-over-time">
                <div id="net-worth-over-time-header">
                    <PresentationPaneHeader displayName={displayName} title={"Net Worth Over Time"}/>
                </div>
                <EmptyStateContainer
                    hideBorder={true}
                    className="insufficient-assets-message"
                    size="large"
                    title='This page cannot be displayed until the profile is sufficient in the "All Assets" scenario.'
                    description={
                        <Button
                            className="asset-reliance-container"
                            icon="right"
                            size="medium"
                            iconName="arrow_right"
                            rounded
                            onClick={() => navigateToAssetReliance()}>
                            Asset Reliance
                        </Button>
                    }
                />
            </div>
    );
}


export default NetWorthOverTime;