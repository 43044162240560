import React from "react";
import {formatCurrency} from "../../utils/format";

type StrategyAccordionHeaderProps = {
    description: string,
    type: string,
    amountToBeneficiaries: number | string,
    impactToEstEstateTax: number,
};

export function StrategyAccordionItemHeader({
                                                description,
                                                type,
                                                amountToBeneficiaries,
                                                impactToEstEstateTax
                                            }: StrategyAccordionHeaderProps) {
    return (
        <div>
            {
                <div className={"strategy-accordion-item"}>
                    <span className={"span-1"}>{description}</span>
                    <span className={"span-2"}>{type}</span>
                    {typeof amountToBeneficiaries === "string" &&
                        <span className={"span-3-color-grey textalign-right"}>{amountToBeneficiaries}</span>}
                    {typeof amountToBeneficiaries === "number" &&
                        <span className={"span-3 textalign-right"}>{formatCurrency(amountToBeneficiaries)}</span>}
                    <span
                        className={"span-4 textalign-right"}>{formatCurrency(impactToEstEstateTax)}
                    </span>
                </div>
            }
        </div>
    );
}