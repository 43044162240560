import {DataDisplayView, ProposedAllocationEntry} from "../ReviseAssetAllocation";
import {formatNumberRoundedToTwoDecimals, formatNumberRoundedToWholeNumber} from "../../../utils/format";
import {ReviseAssetSubclassesSummary} from "./ReviseAssetSubclassesSummary";

export type ResetAssetClassification = {
    updatedAssetClassificationList: ReviseAssetSubclassesSummary[],
    updatedTotalProposedPercentOfTotalPortfolio: number,
    updatedTotalDifferenceAmount: number,
    updatedTotalProposedAmount: number,
    updatedTotalProposedPercent: number
}

type AssetSubclassTotals = {
    currentInvestableAmount: number,
    currentInvestablePercent: number,
    recommendedProposedTargetAmount: number,
    recommendedProposedTargetPercent: number,
    proposedInvestableAmount: number,
    proposedInvestablePercent: number,
    proposedInvestablePercentOfTotalPortfolio: number,
    differenceAmount: number
}


export const calculateProposedInvestablePercentOfTotalPortfolio = (proposedAllocationPercent: number, totalProposedInvestablePercentOfTotalPortfolio: number, totalProposedAllocationPercent: number): number => {
    if (totalProposedAllocationPercent === 0) {
        return 0
    }
    return (proposedAllocationPercent * (totalProposedInvestablePercentOfTotalPortfolio * totalProposedAllocationPercent / 100)) / totalProposedAllocationPercent;
}

export const resetAssetClassification = (
    assetClassificationList: ReviseAssetSubclassesSummary[],
    totalProposedInvestablePercentOfTotalPortfolio: number,
    totalProposedPercent: number,
    totalRecommendedTargetPercent: number,
): ResetAssetClassification => {
    const updatedAssetClassificationList = assetClassificationList.map((assetSubClass) => {
        if (assetSubClass.lockedAmount && assetSubClass.readonly) {
            return assetSubClass;
        }

        const proposedInvestablePercent = assetSubClass.excludeSubclass ? 0 : assetSubClass.recommendedProposedTargetPercent;
        const proposedInvestableAmount = assetSubClass.excludeSubclass ? 0 : assetSubClass.recommendedProposedTargetAmount;
        return ({
            ...assetSubClass,
            proposedInvestablePercent,
            proposedInvestableAmount,
            proposedInvestablePercentOfTotalPortfolio: calculateProposedInvestablePercentOfTotalPortfolio(
                proposedInvestablePercent,
                totalProposedInvestablePercentOfTotalPortfolio,
                totalProposedPercent || totalRecommendedTargetPercent
            ),
            differenceAmount: proposedInvestableAmount - assetSubClass.currentInvestableAmount,
            editedManually: true,
            excludedProposedAllocation: 0
        });
    });
    const updatedTotalProposedPercentOfTotalPortfolio = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.proposedInvestablePercentOfTotalPortfolio;
    }, 0);
    const updatedTotalDifferenceAmount = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.differenceAmount;
    }, 0);

    const updatedTotalProposedAmount = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.proposedInvestableAmount;
    }, 0);

    const updatedTotalProposedPercent = updatedAssetClassificationList.reduce((totalValue, assetSubClass) => {
        return totalValue + assetSubClass.proposedInvestablePercent;
    }, 0);

    return {
        updatedAssetClassificationList,
        updatedTotalProposedPercentOfTotalPortfolio,
        updatedTotalDifferenceAmount,
        updatedTotalProposedAmount,
        updatedTotalProposedPercent
    }
}

function calculateRevisedAmountFromRevisedPercentage(proposedPercent: number, initialTotalProposedAmount: number): number {
    const proposedAmount = (initialTotalProposedAmount * proposedPercent) / 100;
    return Number(proposedAmount.toFixed(2));
}

function calculateRevisedPercentFromRevisedAmount(proposedAmount: number, initialTotalProposedAmount: number): number {
    if (initialTotalProposedAmount == 0) {
        return 0
    }
    const proposedPercent = (proposedAmount / initialTotalProposedAmount) * 100;
    return Number(proposedPercent);
}

function calculateRevisedAmountOrPercent(proposedAllocationEntry: ProposedAllocationEntry, initialTotalProposedAmount: number, displayView: DataDisplayView) {
    if (displayView === DataDisplayView.PERCENTAGE_VIEW) {
        const updatedProposedAmount = calculateRevisedAmountFromRevisedPercentage(proposedAllocationEntry.proposedAllocationPercent!, initialTotalProposedAmount);
        return {
            updatedProposedAmount,
            updatedProposedPercent: proposedAllocationEntry.proposedAllocationPercent!
        }
    } else {
        const updatedProposedPercent = calculateRevisedPercentFromRevisedAmount(proposedAllocationEntry.proposedAllocationAmount!, initialTotalProposedAmount);
        return {
            updatedProposedAmount: proposedAllocationEntry.proposedAllocationAmount!,
            updatedProposedPercent
        }
    }
}

function calculateDollarViewSum(assetList: ReviseAssetSubclassesSummary[], key: keyof AssetSubclassTotals) {
    return assetList.reduce((totals, reviewAssetSubclassSummary) => {
        return totals + Math.round(reviewAssetSubclassSummary[key]);
    }, 0);
}

function calculatePercentViewSum(assetList: ReviseAssetSubclassesSummary[], key: keyof AssetSubclassTotals) {

    return assetList.reduce((totals, reviewAssetSubclassSummary) => {
        return totals + Math.round(reviewAssetSubclassSummary[key] * 100) / 100;
    }, 0);
}

export function updateAssetClassificationForProposedEntry({
                                                              assetClassificationList,
                                                              proposedAllocationEntry,
                                                              previousTotalProposedAmount,
                                                              previousTotalProposedPercent,
                                                              previousTotalProposedPercentOfTotalPortfolio,
                                                              previousTotalDifferenceAmount,
                                                              initialTotalProposedAmount,
                                                              initialTotalProposedPercentOfTotalPortfolio,
                                                              displayView,
                                                              excludeSubclass
                                                          }: {
    assetClassificationList: ReviseAssetSubclassesSummary[],
    proposedAllocationEntry: ProposedAllocationEntry,
    previousTotalProposedAmount: number,
    previousTotalProposedPercent: number,
    previousTotalProposedPercentOfTotalPortfolio: number,
    previousTotalDifferenceAmount: number,
    initialTotalProposedAmount: number,
    initialTotalProposedPercentOfTotalPortfolio: number,
    displayView: DataDisplayView,
    excludeSubclass: boolean
}) {
    const updatedEntryIndex = assetClassificationList.findIndex(subclass => subclass.subclassName === proposedAllocationEntry.assetSubClass);
    const {
        currentInvestableAmount,
        proposedInvestablePercent,
        proposedInvestableAmount,
        proposedInvestablePercentOfTotalPortfolio,
        differenceAmount
    } = assetClassificationList[updatedEntryIndex];

    const {
        updatedProposedAmount,
        updatedProposedPercent
    } = calculateRevisedAmountOrPercent(proposedAllocationEntry, initialTotalProposedAmount, displayView);
    const updatedTotalProposedAmount = previousTotalProposedAmount - proposedInvestableAmount + updatedProposedAmount;
    const updatedTotalProposedPercent = previousTotalProposedPercent - proposedInvestablePercent + updatedProposedPercent;
    const updatedDifferenceAmount = updatedProposedAmount - currentInvestableAmount;
    const updatedTotalDifferenceAmount = previousTotalDifferenceAmount - differenceAmount + updatedDifferenceAmount;
    const updatedProposedPercentOfTotalPortfolio = calculateProposedInvestablePercentOfTotalPortfolio(
        updatedProposedPercent,
        initialTotalProposedPercentOfTotalPortfolio,
        updatedTotalProposedPercent
    );
    const updatedTotalProposedPercentOfTotalPortfolio = previousTotalProposedPercentOfTotalPortfolio - proposedInvestablePercentOfTotalPortfolio + updatedProposedPercentOfTotalPortfolio;

    const updatedRiskAssets = [...assetClassificationList];
    updatedRiskAssets[updatedEntryIndex] = {
        ...updatedRiskAssets[updatedEntryIndex],
        proposedInvestableAmount: updatedProposedAmount,
        proposedInvestablePercent: updatedProposedPercent,
        proposedInvestablePercentOfTotalPortfolio: updatedProposedPercentOfTotalPortfolio,
        differenceAmount: updatedDifferenceAmount,
        excludeSubclass,
        editedManually: proposedAllocationEntry.editedManually,
        excludedProposedAllocation: proposedAllocationEntry.excludedProposedAllocation
    }

    return {
        updatedAssetClassificationList: updatedRiskAssets,
        updatedTotalProposedAmount,
        updatedTotalProposedPercent,
        updatedTotalDifferenceAmount,
        updatedTotalProposedPercentOfTotalPortfolio
    };
}

export const formatAllocationPercentage = (value: number) => {
    if (value === 0 || value === 100) {
        return `${formatNumberRoundedToWholeNumber(value)}%`;
    }
    return `${formatNumberRoundedToTwoDecimals(value)}%`;
};

export const formatAllocationAmount = (value: number) => {
    return formatNumberRoundedToWholeNumber(value);
};

export const isDollarView = (displayView: DataDisplayView): boolean => {
    return displayView === DataDisplayView.DOLLAR_VIEW
}

export const sortAssetClassHierarchy = (reviseAssetAllocation: ReviseAssetSubclassesSummary[]) => {
    return reviseAssetAllocation.reduce((group: { [key: string]: ReviseAssetSubclassesSummary[] }, item) => {
        if (!group[item.assetClassName!]) {
            group[item.assetClassName!] = [];
        }
        group[item.assetClassName!].push(item);
        return group;
    }, {});
};

export const calculateTotalsOfAssetClass = (structuredAssetList: { [key: string]: ReviseAssetSubclassesSummary[] }) => {
    const totalsForLevel2Class: any = {};

    for (const key in structuredAssetList) {
        totalsForLevel2Class[key] = {};

        totalsForLevel2Class[key]['totalCurrentInvestablePercent'] = calculatePercentViewSum(structuredAssetList[key], 'currentInvestablePercent');
        totalsForLevel2Class[key]['totalCurrentInvestableAmount'] = calculateDollarViewSum(structuredAssetList[key], 'currentInvestableAmount');

        totalsForLevel2Class[key]['totalRecommendedProposedTargetPercent'] = calculatePercentViewSum(structuredAssetList[key], 'recommendedProposedTargetPercent');
        totalsForLevel2Class[key]['totalRecommendedProposedTargetAmount'] = calculateDollarViewSum(structuredAssetList[key], 'recommendedProposedTargetAmount');

        totalsForLevel2Class[key]['totalProposedInvestablePercentOfTotalPortfolio'] = calculatePercentViewSum(structuredAssetList[key], 'proposedInvestablePercentOfTotalPortfolio');
        totalsForLevel2Class[key]['totalDifferenceAmount'] = calculateDollarViewSum(structuredAssetList[key], 'differenceAmount');

        totalsForLevel2Class[key]['totalProposedInvestablePercent'] = calculatePercentViewSum(structuredAssetList[key], 'proposedInvestablePercent');
        totalsForLevel2Class[key]['totalProposedInvestableAmount'] = calculateDollarViewSum(structuredAssetList[key], 'proposedInvestableAmount');
    }

    return totalsForLevel2Class;
}