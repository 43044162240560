import React from "react";

interface StrategyListHeaderProps {
    isInReportPreview: boolean;
}

export const StrategyListHeader = ({
                                       isInReportPreview
                                   }: StrategyListHeaderProps) => {
    return (
        <div className={isInReportPreview ? "strategy-list-header__report" : "strategy-list-header__web"}>
            <span className={"span-1"}>Name</span>
            <span className={"span-2"}>Strategy</span>
            <span className={"span-3 textalign-right"}>Impact to Amount to Beneficiaries</span>
            <span className={"span-4 textalign-right"}>Impact to  Est. Estate Tax</span>
        </div>
    );
}