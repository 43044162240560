export const InstitutionOptions = [
    { label: "Fidelity", value: "Fidelity" },
    { label: "Charles Schwab", value: "Charles Schwab" },
    { label: "Morgan Stanley", value: "Morgan Stanley" },
    { label: "Vanguard", value: "Vanguard" },
    { label: "JP Morgan Chase & Co.", value: "JP Morgan Chase & Co." },
    { label: "Goldman Sachs", value: "Goldman Sachs" },
    { label: "UBS", value: "UBS" },
    { label: "Merrill Lynch", value: "Merrill Lynch" },
    { label: "Wells Fargo", value: "Wells Fargo" },
    { label: "Bank of America", value: "Bank of America" },
    { label: "Northwestern Mutual", value: "Northwestern Mutual" },
    { label: "Raymond James", value: "Raymond James" },
    { label: "TD Ameritrade", value: "TD Ameritrade" },
    { label: "BMO Harris", value: "BMO Harris" },
    { label: "Eaton Vance", value: "Eaton Vance" },
    { label: "Bessemer Trust", value: "Bessemer Trust" },
    { label: "US Bank", value: "US Bank" },
    { label: "E*Trade", value: "E*Trade" },
    { label: "Plante Moran", value: "Plante Moran" },
    { label: "Bernstein", value: "Bernstein" },
    { label: "Edward Jones", value: "Edward Jones" },
    { label: "BNY Mellon", value: "BNY Mellon" },
    { label: "RBC", value: "RBC" },
    { label: "US Trust", value: "US Trust" },
    { label: "Northern Trust", value: "Northern Trust"},
    { label: "None", value: "None" },
    { label: "Other", value: "Other" }
];